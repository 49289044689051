import { connect } from "react-redux";

import { RootState } from "../redux/rootReducer";
import EmbedChapter from "./EmbedChapter";

const mapStateToProps = (state: RootState) => {
  return {
    guid: state.user?.guid,
  };
};

export default connect(mapStateToProps)(EmbedChapter);
