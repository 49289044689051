import { combineReducers } from "redux";

import numTextbooksInGridReducer from "../TextbookBrowser/numTextbooksInGridReducer";
import textbooksReducer from "../TextbookBrowser/textbooksReducer";
import authReducer from "../withAuth/authReducer";
import userReducer from "./user/userReducer";
import favouriteBooksApi from "../api/favouriteBooksApiSlice";

export type RootState = ReturnType<typeof rootReducer>;

export const rootReducer = combineReducers({
  textbooks: textbooksReducer,
  numTextbooksInGrid: numTextbooksInGridReducer,
  auth: authReducer,
  user: userReducer,
  [favouriteBooksApi.reducerPath]: favouriteBooksApi.reducer,
});
