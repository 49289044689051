import {
  TextbookCategoryWithCount,
  sendCategorySelectEchoEvent,
} from "../lib/textbooksHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InstAwareLink from "../AppRouter/InstAwareLink";
import { useParams } from "react-router-dom";
import { getCategoryInfo } from "./categoryInfo";
import { useMemo } from "react";

export type CategoryCardProps = {
  category: TextbookCategoryWithCount;
  userGuid: string;
};

const CategoryCard = ({ category, userGuid }: CategoryCardProps) => {
  const { instCode } = useParams() as { instCode: string };
  const { link, iconStyles, bookCount } = useMemo(
    () => getCategoryInfo(category),
    [category],
  );

  return (
    <div className="col-xs-12 col-md-2">
      <div className="c-category-card rounded-2 h-100 py-2">
        <InstAwareLink
          to={link}
          onClick={() =>
            sendCategorySelectEchoEvent(category, instCode, userGuid)
          }
        >
          <FontAwesomeIcon
            icon={iconStyles.icon}
            style={{ color: iconStyles.colour }}
            className="fa-2x my-3"
          />
          <div className="fw-semibold">{category.title}</div>
          <div className="c-category-book-count">{bookCount}</div>
        </InstAwareLink>
      </div>
    </div>
  );
};

export default CategoryCard;
