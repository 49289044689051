import { ChangeEvent, useEffect, useReducer } from "react";
import { useParams } from "react-router-dom";

import { ContactUsData, postContactUsData } from "../lib/elevateHelper";
import { ContactUsActionType, reducer } from "./contactUs.reducer";
import ContactUsFormDialog from "./ContactUsFormDialog";
import ContactUsSuccessDialog from "./ContactUsSuccessDialog";

export interface ContactUsProps {
  isOpen: boolean;
  onDismiss: () => void;
  emailAddress: string;
  initialFormData: ContactUsData;
}

const ContactUs = ({
  isOpen,
  onDismiss,
  emailAddress,
  initialFormData,
}: ContactUsProps): JSX.Element => {
  const { instCode } = useParams() as { instCode: string };
  const [{ formData, isProcessing, isSuccessful, errorMessage }, dispatcher] =
    useReducer(reducer, {
      formData: initialFormData,
      isProcessing: false,
      isSuccessful: false,
    });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) =>
    dispatcher({
      type: ContactUsActionType.FORM_HANDLE_CHANGE,
      payload: {
        key: event.target.id,
        value: event.target.value,
      },
    });

  const handleSubmit = () => {
    dispatcher({ type: ContactUsActionType.START_PROCESSING });
    postContactUsData(formData, instCode, emailAddress)
      .then(() => {
        dispatcher({ type: ContactUsActionType.SUCCESS });
      })
      .catch(() => {
        dispatcher({
          type: ContactUsActionType.FAILED,
          payload: {
            errorMessage:
              "An error occurred sending your contact information, please try again later.",
          },
        });
      });
  };

  useEffect(() => {
    return dispatcher({
      type: ContactUsActionType.RESET,
      payload: { formData: initialFormData },
    });
  }, [isOpen, initialFormData]);

  return isSuccessful ? (
    <ContactUsSuccessDialog {...{ isOpen, onDismiss }} />
  ) : (
    <ContactUsFormDialog
      {...{
        isOpen,
        onDismiss,
        errorMessage,
        formData,
        isProcessing,
        handleChange,
        handleSubmit,
      }}
    />
  );
};

export default ContactUs;
