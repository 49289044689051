import slugify from "slugify";

import { TableOfContentsEntryWithPlayerLink } from "../TableOfContents/TableOfContents";
import { TableOfContentsEntry } from "../TextbookBrowser";
import { Textbook, Textbooks } from "../TextbookBrowser/textbooksState";
import config from "../utils/config";
import { getAuthorsCitation } from "./authorNamesHelper";
import { sendSearchEvent } from "./elevateHelper";
import { TextbookSortBy } from "../TextbookBrowser/SortByDropdown";

export interface TextbookCategoryWithCount {
  title: string;
  bookCount: number;
  slug: string;
}

export enum filterType {
  CATEGORY = "category",
  SUBCATEGORY = "subcategory",
}
const sortCategoryTextbooks = (
  a: TextbookCategoryWithCount,
  b: TextbookCategoryWithCount,
): number => {
  const titleA = a.title.toUpperCase();
  const titleB = b.title.toUpperCase();
  return titleA < titleB ? -1 : 1;
};

export const convertToSlug = (input: string): string => {
  return slugify(input, {
    lower: true,
    strict: true,
  });
};

export const getCategoryNameFromSlug = (
  textbooks: Textbooks,
  input: string | null,
): string => {
  if (!input) return "";

  return textbooks.reduce((matchingCategory, { category }) => {
    const slug = convertToSlug(category);
    if (slug === input) {
      return category;
    }
    return matchingCategory;
  }, "");
};

export const getSubcategoryNameFromSlug = (
  textbooks: Textbooks,
  input: string | null,
): string => {
  if (!input) return "";

  return textbooks.reduce((matchingSubcategory, { subCategory }) => {
    if (subCategory) {
      const slug = convertToSlug(subCategory);
      if (slug === input) {
        return subCategory;
      }
    }
    return matchingSubcategory;
  }, "");
};

export const countTextbooksByCategory = (
  textbooks: Textbooks,
  filter: filterType,
): Array<TextbookCategoryWithCount> => {
  const categoryCounts: Record<string, number> = {};

  textbooks.forEach((textbook: Textbook) => {
    let categoryName: string | undefined;

    if (filter === filterType.CATEGORY) {
      categoryName = textbook.category;
    } else if (filter === filterType.SUBCATEGORY) {
      categoryName = textbook.subCategory;
    }

    if (!categoryName) {
      return;
    }

    if (categoryCounts[categoryName] === undefined) {
      categoryCounts[categoryName] = 1;
    } else {
      categoryCounts[categoryName]++;
    }
  });

  const categoryTextbookCount = Object.keys(categoryCounts).map(
    (category: string) => {
      return {
        title: category,
        bookCount: categoryCounts[category],
        slug: convertToSlug(category),
      };
    },
  );

  return categoryTextbookCount.sort(sortCategoryTextbooks);
};

export const sendCategorySelectEchoEvent = (
  category: TextbookCategoryWithCount,
  instCode: string,
  userGuid: string,
) => {
  const searchEventData = {
    instShortCode: instCode,
    searchedByISBN: false,
    userGuid: userGuid,
    searchQuery: category.title,
    hitCount: category.bookCount,
  };

  return sendSearchEvent(searchEventData);
};

export const getTableOfContentsWithLinks = (
  instCode: string,
  textbook: Textbook,
): TableOfContentsEntryWithPlayerLink[] => {
  return textbook.tableOfContents.map(
    (entry: TableOfContentsEntry): TableOfContentsEntryWithPlayerLink => {
      const playerLink = `${config.elevateUrl}${instCode}/textbooks/${textbook.isbn}?pageIndex=${entry.startPage}&pdfOnly=true`;
      return {
        ...entry,
        playerLink,
      };
    },
  );
};

export const addOrdinalSuffix = (num: string): string => {
  const int = parseInt(num),
    digits = [int % 10, int % 100],
    ordinals = ["st", "nd", "rd", "th"],
    oPattern = [1, 2, 3, 4],
    tPattern = [11, 12, 13, 14, 15, 16, 17, 18, 19];
  return oPattern.includes(digits[0]) && !tPattern.includes(digits[1])
    ? int + ordinals[digits[0] - 1]
    : int + ordinals[3];
};

export const getPublicationYear = (textbook: {
  publicationDate: string | number;
}): string => {
  if (typeof textbook.publicationDate === "string") {
    return textbook.publicationDate.substring(0, 4);
  } else {
    return textbook.publicationDate.toString();
  }
};

export const createChapterDescriptionFromTextbook = (
  textbook: Textbook,
): string => {
  const textbookInfo = [textbook.title];

  if (textbook.editionDescription) {
    textbookInfo.push(textbook.editionDescription);
  } else if (textbook.editionNumber) {
    textbookInfo.push(`${addOrdinalSuffix(textbook.editionNumber)} edition`);
  }

  const publicationYear = getPublicationYear(textbook);
  textbookInfo.push(publicationYear);

  const authorNames = getAuthorsCitation([
    textbook.contributor1,
    textbook.contributor2,
    textbook.contributor3,
  ]);

  return `From ${textbookInfo.join(", ")} written by ${authorNames}`;
};

const compareValues = (valueA: string, valueB: string, ascending = true) => {
  const orderMultiplier = ascending ? 1 : -1;

  if (valueA < valueB) {
    return -1 * orderMultiplier;
  }

  if (valueA > valueB) {
    return 1 * orderMultiplier;
  }

  return 0;
};

export const sortTextbooks = (
  a: Textbook,
  b: Textbook,
  sortBy: TextbookSortBy,
): number => {
  switch (sortBy) {
    case TextbookSortBy.ALPHABETICAL: {
      const aTitle = a.title;
      const bTitle = b.title;

      return compareValues(aTitle, bTitle);
    }
    case TextbookSortBy.PUBLICATION_DATE: {
      const aDate = a.publicationDate;
      const bDate = b.publicationDate;

      return compareValues(aDate, bDate, false);
    }
  }
};
