import "./TextbookBrowser.scss";

import { createRef, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import GridListView from "../GridListView";
import {
  TextbookCategoryWithCount,
  convertToSlug,
  countTextbooksByCategory,
  filterType,
  getCategoryNameFromSlug,
  getSubcategoryNameFromSlug,
  sendCategorySelectEchoEvent,
  sortTextbooks,
} from "../lib/textbooksHelper";
import TextbookListItem from "../TextbookListItem";
import TextbookSearch from "../TextbookSearch";
import { Textbook, TextbookBrowserProps, Textbooks } from ".";
import CategoryDropdown from "./CategoryDropdown";
import SortByDropdown, { TextbookSortBy } from "./SortByDropdown";
import SubcategoryDropdown from "./SubcategoryDropdown";
import InstAwareLink from "../AppRouter/InstAwareLink";

const generateListItems = (
  textbooks: Textbooks,
  filteredCategory: string,
  sortBy: TextbookSortBy,
  refs: React.MutableRefObject<React.RefObject<HTMLAnchorElement>[]>,
  filteredSubcategory?: string,
) => {
  const textbookListItems = textbooks
    .filter(
      ({ category }) => !filteredCategory || category === filteredCategory,
    )
    .filter(
      ({ subCategory }) =>
        !filteredSubcategory || subCategory === filteredSubcategory,
    )
    .sort((a, b) => sortTextbooks(a, b, sortBy))
    .map((textbook: Textbook, index: number) => {
      const { title, isbn, publicationDate } = textbook;
      const textbookListItem = {
        title,
        authors: [
          textbook.contributor1,
          textbook.contributor2,
          textbook.contributor3,
        ],
        isbn,
        publicationDate,
      };
      return (
        <li key={isbn}>
          <InstAwareLink
            to={`/books/${isbn}`}
            className="textbook-item-text"
            ref={refs.current[index]}
          >
            <TextbookListItem textbook={textbookListItem} />
          </InstAwareLink>
        </li>
      );
    });
  return textbookListItems;
};
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const TextbookBrowser = (props: TextbookBrowserProps): JSX.Element => {
  const {
    textbooks,
    numTextbooksInGrid,
    incrementNumTextbooksInGrid,
    userGuid,
  } = props;
  const [filteredCategory, updateFilter] = useState("");
  const [filteredSubcategory, updateSubcategoryFilter] = useState("");
  const [selectedSortBy, setSelectedSortBy] = useState(
    TextbookSortBy.PUBLICATION_DATE,
  );
  const [bookToFocusOn, setBookToFocusOn] = useState(0);
  const refs = useRef(textbooks.map(() => createRef<HTMLAnchorElement>()));
  const query = useQuery();
  const { instCode } = useParams() as { instCode: string };
  const navigate = useNavigate();

  const focusOnBook = (index: number) =>
    refs.current[index]?.current?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
    });

  useEffect(() => {
    const category = query.get("category");
    const subcategory = query.get("subcategory");
    updateFilter(getCategoryNameFromSlug(textbooks, category));
    updateSubcategoryFilter(getSubcategoryNameFromSlug(textbooks, subcategory));

    if (category) {
      focusOnBook(0);
    }
  }, [updateFilter, updateSubcategoryFilter, query, textbooks]);

  useEffect(() => {
    if (bookToFocusOn === 0) {
      return;
    }

    focusOnBook(bookToFocusOn);
  }, [bookToFocusOn, numTextbooksInGrid]);

  const loadMore = () => {
    setBookToFocusOn(numTextbooksInGrid);
    incrementNumTextbooksInGrid();
  };

  const textbookListItems = useMemo(
    () =>
      generateListItems(
        textbooks,
        filteredCategory,
        selectedSortBy,
        refs,
        filteredSubcategory,
      ),
    [filteredCategory, filteredSubcategory, selectedSortBy, textbooks],
  );

  const categoriesWithCountsAndSlugs = useMemo(
    () => countTextbooksByCategory(textbooks, filterType.CATEGORY),
    [textbooks],
  );

  const categorisedTextbooks = textbooks.filter(
    ({ category }) => !filteredCategory || category === filteredCategory,
  );

  const subcategoryWithCountsAndSlugs = useMemo(
    () =>
      countTextbooksByCategory(categorisedTextbooks, filterType.SUBCATEGORY),
    [categorisedTextbooks],
  );

  const filteredCategoryBookLimit = categoriesWithCountsAndSlugs.find(
    ({ title }) => title === filteredCategory,
  )?.bookCount;

  const areAllBooksLoaded = filteredCategoryBookLimit
    ? numTextbooksInGrid >= filteredCategoryBookLimit
    : numTextbooksInGrid >= textbooks.length;

  const onNavigate = async (category?: TextbookCategoryWithCount) => {
    if (!category) {
      return navigate(`/${instCode}/books`);
    }
    if (userGuid) {
      await sendCategorySelectEchoEvent(category, instCode, userGuid);
    }
    navigate(`/${instCode}/books?category=${category.slug}`);
  };

  const onSubcategoryNavigate = async (
    filteredCategory?: string,
    subcategory?: TextbookCategoryWithCount,
  ) => {
    const queryParams = [];

    if (filteredCategory) {
      queryParams.push(`category=${filteredCategory}`);
    }

    if (subcategory) {
      if (userGuid) {
        await sendCategorySelectEchoEvent(subcategory, instCode, userGuid);
      }
      queryParams.push(`subcategory=${subcategory.slug}`);
    }

    const querySeparator = queryParams.length > 0 ? "?" : "";
    const navigationUrl = `/${instCode}/books${querySeparator}${queryParams.join(
      "&",
    )}`;

    navigate(navigationUrl);
  };

  return (
    <div className="textbook-browser flex-grow-1">
      <TextbookSearch textbooks={textbooks} />

      <section className="wrapper px-3">
        <section className="category-results pb-5 d-flex flex-column">
          <div className="pt-3 ms-2">
            <CategoryDropdown
              selectedCategory={filteredCategory}
              allCategories={categoriesWithCountsAndSlugs}
              onNavigate={onNavigate}
              totalTextbooks={textbooks.length}
            />
            <SubcategoryDropdown
              selectedCategory={convertToSlug(filteredCategory)}
              selectedSubcategory={filteredSubcategory}
              allSubcategories={subcategoryWithCountsAndSlugs}
              onNavigate={onSubcategoryNavigate}
            />
            <SortByDropdown
              selectedSortBy={selectedSortBy}
              onSortSelect={setSelectedSortBy}
            />
          </div>
          <div>
            <GridListView numItemsToRender={numTextbooksInGrid}>
              {textbookListItems}
            </GridListView>
          </div>

          {!areAllBooksLoaded && (
            <button
              onClick={loadMore}
              className="btn btn-primary btn-lg align-self-center fs-3"
              type="button"
            >
              Load More
            </button>
          )}
        </section>
      </section>
    </div>
  );
};

export default TextbookBrowser;
