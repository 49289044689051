import { connect } from "react-redux";

import { RootState } from "../redux/rootReducer";
import ContactUs from "./ContactUs";

const mapStateToProps = (state: RootState) => {
  return {
    emailAddress: state?.user?.profile?.email || "",
    initialFormData: {
      fullName: state?.user?.fullName || "",
      jobTitle: "",
      department: "",
      university: "",
    },
  };
};

export default connect(mapStateToProps)(ContactUs);
