import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { RootState } from "../redux/rootReducer";
import { Textbook, Textbooks } from "../TextbookBrowser/textbooksState";
import TextbookSearch from "./TextbookSearch";

interface RouterAwareTextbookSearchProps {
  textbooks: Textbooks;
  userGuid: string;
}

const RouterAwareTextbookSearch = (
  props: RouterAwareTextbookSearchProps,
): JSX.Element => {
  const navigate = useNavigate();
  const { instCode } = useParams() as { instCode: string };

  const navigateToBook = (textbook: Textbook) => {
    navigate(`/${instCode}/books/${textbook.isbn}`);
  };

  return <TextbookSearch onSelectTextbook={navigateToBook} {...props} />;
};

const mapStateToProps = (state: RootState) => {
  return {
    userGuid: state.user?.guid || "",
  };
};

// eslint-disable-next-line react-redux/prefer-separate-component-file
export default connect(mapStateToProps)(RouterAwareTextbookSearch);
