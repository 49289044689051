import "../TextbookBrowser.scss";
import { TextbookCategoryWithCount } from "../../lib/textbooksHelper";

export interface SubcategoryDropdownProps {
  selectedCategory: string;
  selectedSubcategory: string;
  allSubcategories: TextbookCategoryWithCount[];
  onNavigate: (
    selectedCategory?: string,
    subcategory?: TextbookCategoryWithCount,
  ) => void;
}

const SubcategoryDropdown = ({
  selectedCategory,
  selectedSubcategory,
  allSubcategories,
  onNavigate,
}: SubcategoryDropdownProps): JSX.Element => {
  const changeSubcategory = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedSubcategoryTitle = e.target.value;
    if (selectedSubcategoryTitle) {
      const subcategory = allSubcategories.find(
        (subcategory) => subcategory.title === selectedSubcategoryTitle,
      );
      onNavigate(selectedCategory, subcategory);
    }
  };
  return (
    <select
      className="btn btn-default ms-3 category-dropdown-text-size text-start"
      id="subcategoryList"
      onChange={(e) => changeSubcategory(e)}
      aria-label="Subdiscipline List"
      value={selectedSubcategory}
    >
      <option value="" disabled>
        Subdiscipline
      </option>
      {allSubcategories.map((subcategory) => {
        const { title, bookCount } = subcategory;
        return (
          <option value={title} key={title}>
            {title}
            {` (${bookCount})`}
          </option>
        );
      })}
    </select>
  );
};

export default SubcategoryDropdown;
