import ScrollToTop from "../ScrollToTop";
import AppRouter from "./AppRouter";

const AppRouterWithBrowserRouter = (): JSX.Element => {
  return (
    <>
      <ScrollToTop />
      <AppRouter />
    </>
  );
};

export default AppRouterWithBrowserRouter;
