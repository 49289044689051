import TextbookImage from "../TextbookImage";
import InstAwareLink from "../AppRouter/InstAwareLink";
import "./RecommendedTitles.scss";
import { useCallback, useEffect, useState } from "react";
import { fetchRecommendedTitles } from "../lib/elevateHelper";
import { Textbook } from "../TextbookBrowser";

export type RecommendedTitlesProps = {
  instCode: string;
  isbn: string;
  textbooks: Textbook[];
};

const RecommendedTitles = ({
  isbn,
  instCode,
  textbooks,
}: RecommendedTitlesProps) => {
  const [recommendations, setRecommendations] = useState<Textbook[]>([]);

  const getRecommendations = useCallback(async () => {
    try {
      const recommendedIsbns = await fetchRecommendedTitles(instCode, isbn);

      const titlesToRecommend = recommendedIsbns
        .map((recommendedTitle) => {
          return textbooks.find(
            (textbook) => textbook.isbn === recommendedTitle.isbn,
          );
        })
        .filter((recommendation) => recommendation !== undefined) as Textbook[];

      setRecommendations(titlesToRecommend);
    } catch (e) {}
  }, [instCode, isbn, textbooks]);

  useEffect(() => {
    getRecommendations();
  }, [getRecommendations]);

  if (recommendations.length === 0) {
    return null;
  }

  const recommendationElements = recommendations.map((recommendedTitle) => (
    <li key={recommendedTitle.isbn} className="position-relative mb-4">
      <TextbookImage isbn={recommendedTitle.isbn} width={156} />
      <InstAwareLink
        to={"/books/" + recommendedTitle.isbn}
        className="d-block text-black fw-bold"
      >
        {recommendedTitle.title}
      </InstAwareLink>
    </li>
  ));

  return (
    <div className="c-recommended-titles">
      <h2 className="text-black">Similar Books</h2>

      <ol className="c-recommended-titles-list list-unstyled">
        {recommendationElements}
      </ol>
    </div>
  );
};

export default RecommendedTitles;
