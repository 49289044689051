export interface TextbookImageProps {
  isbn: string;
  width: number;
}

const TextbookImage = (props: TextbookImageProps): JSX.Element => {
  const { isbn, width } = props;

  const size = width <= 156 ? "thumbnail" : "full";
  const url = `https://catalyst-bookjackets.talis.com/jackets/${isbn}-${size}`;

  const webpUrl = `${url}.webp`;
  const jpgUrl = `${url}.jpg`;

  return (
    <picture>
      <source srcSet={webpUrl} />
      <img
        src={jpgUrl}
        className="img-responsive mb-3 max-w-100 object-fit-cover"
        alt=""
        width={width}
        loading="lazy"
      />
    </picture>
  );
};

export default TextbookImage;
