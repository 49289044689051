import InstAwareLink from "../AppRouter/InstAwareLink";

const PageNotFound = (): JSX.Element => {
  return (
    <section className="w-50 my-5 mx-auto qa-pagenotfound">
      <div className="wrapper">
        <h1>Oops!</h1>
        <h2 className="mb-5 mt-0 fs-2">
          We can&apos;t find the page you&apos;re looking for.
        </h2>
        <p className="fs-3">
          Try visiting the{" "}
          <InstAwareLink to="/books">Textbook Browser</InstAwareLink> where you
          can browse the list of textbooks we have available.
        </p>
      </div>
    </section>
  );
};

export default PageNotFound;
