import "./ModuleSearch.scss";

import { ChangeEvent } from "react";

import { ElevateModule } from "./FindModuleDialog";

type ModuleSearchProps = {
  modules: Array<ElevateModule>;
  results: Array<ElevateModule>;
  searchValue: string;
  selectedModule: string;
  selectModule: CallableFunction;
  handleSearchChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

const moduleDateFormat = "Do MMM, YYYY";

const ModuleSearch = ({
  modules,
  results,
  searchValue,
  selectModule,
  selectedModule,
  handleSearchChange,
}: ModuleSearchProps): JSX.Element => {
  const modulesToShow = results.length !== 0 ? results : modules;
  return (
    <>
      <form>
        <div className="form-group">
          <label htmlFor="searchModules">
            <span className="visually-hidden">
              Search by module name or code
            </span>
          </label>
          <input
            className="form-control"
            id="searchModules"
            onChange={handleSearchChange}
            placeholder="Search by module name or code"
            value={searchValue}
          />
        </div>
      </form>
      {results.length === 0 && searchValue ? (
        <p className="modal__text" data-testid="qa-no-search-results">
          There are no matches for the search <strong>{searchValue}</strong>.
        </p>
      ) : (
        <div className="c-find-module__scrollable-table overflow-hidden overflow-y-scroll w-100">
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">Module Title</th>
                <th scope="col">Code</th>
                <th scope="col">Start Date</th>
              </tr>
            </thead>
            <tbody data-testid="qa-module-search-table-body">
              {modulesToShow.map(({ id, title, code, startDate }) => (
                <tr key={id}>
                  <td>
                    <label htmlFor={`module-${id}`} className="my-3">
                      <input
                        type="radio"
                        id={`module-${id}`}
                        name="moduleCode"
                        value={id}
                        onChange={(e) => {
                          selectModule(e.target.value);
                        }}
                        checked={selectedModule === id}
                      />
                      <span className="c-find-module__module-title ps-3">
                        {title}
                      </span>
                    </label>
                  </td>
                  <td>{code}</td>
                  <td>{startDate.format(moduleDateFormat)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default ModuleSearch;
