import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { RootState } from "../redux/rootReducer";
import { fetchAuthenticatedUserThunk } from "./authReducer";
import { withAuthProps } from "./index";

type withAuthHoc<T> = (props: Omit<T, keyof withAuthProps>) => JSX.Element;

export function withAuth<T extends withAuthProps>(
  WrappedComponent: React.ComponentType<T>,
): withAuthHoc<T> {
  const ComponentWithAuth = (props: Omit<T, keyof withAuthProps>) => {
    const dispatch = useAppDispatch();
    const { instCode } = useParams() as { instCode: string };

    const selectCurrentAuthStatus = (state: RootState) =>
      state.auth.currentAuthStatus;
    const currentAuthStatus = useAppSelector(selectCurrentAuthStatus);

    useEffect(() => {
      dispatch(fetchAuthenticatedUserThunk(instCode));
    }, [dispatch, instCode]);

    const authProps = {
      currentAuthStatus,
    };

    return <WrappedComponent {...authProps} {...(props as T)} />;
  };

  return ComponentWithAuth;
}

export default withAuth;
