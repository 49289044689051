type ChapterToEmbedState = {
  isProcessing: boolean;
  errorMessage?: string;
  resourceUrl?: string;
  moduleTitle?: string;
};

type EmbedChapterAction =
  | { type: "START_PROCESSING" }
  | { type: "RESET" }
  | { type: "SUCCESS"; payload: { resourceUrl: string; moduleTitle: string } }
  | { type: "FAILED"; payload: { errorMessage: string } };

export const initialState = {
  isProcessing: false,
  errorMessage: undefined,
  resourceUrl: undefined,
  moduleTitle: undefined,
};

export const reducer = (
  state: ChapterToEmbedState,
  action: EmbedChapterAction,
): ChapterToEmbedState => {
  switch (action.type) {
    case "START_PROCESSING":
      return {
        ...state,
        isProcessing: true,
      };
    case "SUCCESS":
      return {
        ...state,
        isProcessing: false,
        moduleTitle: action.payload.moduleTitle,
        resourceUrl: action.payload.resourceUrl,
      };
    case "FAILED":
      return {
        ...state,
        isProcessing: false,
        errorMessage: action.payload.errorMessage,
      };
    case "RESET":
      return initialState;
  }
  return state;
};
