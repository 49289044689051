import SaveFavouriteBook from "./SaveFavouriteBook";
import { connect } from "react-redux";

import { RootState } from "../../redux/rootReducer";

const mapStateToProps = (state: RootState) => {
  return {
    userGuid: state.user?.guid || "",
  };
};
export default connect(mapStateToProps)(SaveFavouriteBook);
