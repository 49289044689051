import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "./axiosBaseQuery";
import { generateUrl } from "./apiHelper";
import { RootState } from "../redux/rootReducer";

type FavouriteData = {
  data: Array<{
    attributes: { isbn: string };
    id: string;
    type: string;
  }>;
};
type fetchFavouriteBooksQueryArgs = {
  instCode: string;
  userGuid: string;
};

const favouriteBooksApi = createApi({
  reducerPath: "favouriteBooksApi",
  baseQuery: axiosBaseQuery,
  endpoints: (builder) => ({
    fetchFavouriteBooks: builder.query<string[], fetchFavouriteBooksQueryArgs>({
      query: ({ instCode, userGuid }) => {
        return {
          url: generateUrl(
            `1/${instCode}/textbooks/favourite?filter[user]=${userGuid}`,
          ),
          method: "GET",
        };
      },
      transformResponse: (response: FavouriteData) => {
        const uniqueISBNs = new Set<string>();

        response.data.forEach((data) => {
          if (data.attributes.isbn) {
            uniqueISBNs.add(data.attributes.isbn);
          }
        });

        return Array.from(uniqueISBNs);
      },
    }),
    saveFavouriteBook: builder.mutation<
      void,
      {
        instCode: string;
        isbn: string;
      }
    >({
      query: ({ instCode, isbn }) => {
        return {
          url: generateUrl(`1/${instCode}/textbooks/${isbn}/favourite`),
          method: "POST",
        };
      },
    }),
    deleteFavouriteBook: builder.mutation<
      void,
      {
        instCode: string;
        isbn: string;
      }
    >({
      query: ({ instCode, isbn }) => {
        return {
          url: generateUrl(`1/${instCode}/textbooks/${isbn}/favourite`),
          method: "DELETE",
        };
      },
    }),
  }),
});

export const upsertFavouriteBooks =
  (queryArgs: fetchFavouriteBooksQueryArgs, isbn: string) =>
  async (dispatch: CallableFunction, getState: () => RootState) => {
    const data =
      favouriteBooksApi.endpoints.fetchFavouriteBooks.select(queryArgs)(
        getState(),
      ).data || [];

    dispatch(
      favouriteBooksApi.util.upsertQueryData("fetchFavouriteBooks", queryArgs, [
        ...data,
        isbn,
      ]),
    );
  };

export const downsertFavouriteBooks =
  (queryArgs: fetchFavouriteBooksQueryArgs, isbn: string) =>
  async (dispatch: CallableFunction, getState: () => RootState) => {
    const data =
      favouriteBooksApi.endpoints.fetchFavouriteBooks.select(queryArgs)(
        getState(),
      ).data || [];

    const newData = data.filter((existingIsbn) => existingIsbn !== isbn);
    dispatch(
      favouriteBooksApi.util.upsertQueryData(
        "fetchFavouriteBooks",
        queryArgs,
        newData,
      ),
    );
  };

export const {
  useFetchFavouriteBooksQuery,
  useSaveFavouriteBookMutation,
  useDeleteFavouriteBookMutation,
} = favouriteBooksApi;

export default favouriteBooksApi;
