export interface SearchOptions {
  fields: Array<string>;
  limit: number;
}

export interface SearchItem<T> {
  score: number;
  item: T;
}

export interface Searchable {
  [key: string]: string | unknown;
}

const calculateScore = (haystack: string, needle: string): number => {
  if (haystack === needle) {
    return 100;
  }

  const haystackParts = haystack.match(/[\w]+/g);
  const needleParts = needle.match(/[\w]+/g);

  let score = 0;

  haystackParts?.forEach((haystackPart) => {
    needleParts?.forEach((needlePart) => {
      const wordMatchRegex = new RegExp(`\\b${needlePart}\\b`);
      if (haystackPart.match(wordMatchRegex)) {
        score += 10;
      }

      const partialWordMatchRegex = new RegExp(needlePart);
      if (haystackPart.match(partialWordMatchRegex)) {
        score += 1;
      }
    });
  });

  return score;
};

const convertToSearchItem = <T>(item: T): SearchItem<T> => {
  return {
    score: 0,
    item,
  };
};

const applyScoresToItem =
  <T extends Searchable>(searchTerm: string, fields: Array<string>) =>
  (searchItem: SearchItem<T>): SearchItem<T> => {
    const score = fields.reduce((runningScore, field) => {
      const itemFieldValue = searchItem.item[field];
      if (typeof itemFieldValue !== "string") {
        return runningScore;
      }
      return (
        runningScore + calculateScore(itemFieldValue.toLowerCase(), searchTerm)
      );
    }, 0);

    return {
      ...searchItem,
      score: score,
    };
  };

const removeZeroRankedItems = <T>(item: SearchItem<T>): boolean =>
  item.score > 0;

const orderBySearchResults = <T>(a: SearchItem<T>, b: SearchItem<T>): number =>
  b.score - a.score;

const convertBackFromSearchItem = <T>(searchItem: SearchItem<T>) =>
  searchItem.item;

const performSearch =
  <T extends Searchable>(items: Array<T>, searchOptions: SearchOptions) =>
  (searchTerm: string): Array<T> => {
    if (searchTerm === "") {
      return [];
    }

    const { fields, limit } = searchOptions;
    const loweredSearchTerm = searchTerm.toLowerCase();

    const rankedItems = items
      .map(convertToSearchItem)
      .map(applyScoresToItem(loweredSearchTerm, fields))
      .filter(removeZeroRankedItems)
      .sort(orderBySearchResults)
      .map(convertBackFromSearchItem);

    return rankedItems.slice(0, limit);
  };

export default performSearch;
