import { useCallback, useEffect, useState } from "react";

import {
  EPubTableOfContentsEntry,
  fetchEPubTableOfContents,
} from "../lib/elevateHelper";
import config from "../utils/config";

enum LoadingState {
  Loading,
  Loaded,
  Error,
}

export type EPubTableOfContentsProps = {
  instCode: string;
  isbn: string;
};

const getPlayerLink = (instCode: string, isbn: string, page: number): string =>
  `${config.elevateUrl}${instCode}/textbooks/${isbn}?pageIndex=${page}`;

const EPubTableOfContents = ({
  instCode,
  isbn,
}: EPubTableOfContentsProps): JSX.Element => {
  const [contents, setContents] = useState<EPubTableOfContentsEntry[]>([]);
  const [loading, setLoading] = useState<LoadingState>(LoadingState.Loading);

  const getEPubTableOfContents = useCallback(async () => {
    setLoading(LoadingState.Loading);

    try {
      const tableOfContents = await fetchEPubTableOfContents(instCode, isbn);
      setContents(tableOfContents);
      setLoading(LoadingState.Loaded);
    } catch {
      setLoading(LoadingState.Error);
    }
  }, [instCode, isbn]);

  useEffect(() => {
    getEPubTableOfContents();
  }, [getEPubTableOfContents]);

  if (loading === LoadingState.Loading) {
    return <div>Loading...</div>;
  }

  if (loading === LoadingState.Error) {
    return (
      <div className="talis-alert-warning p-4 text-black" role="alert">
        An error occurred loading the table of contents.
      </div>
    );
  }

  return (
    <ol className="c-table-of-contents">
      {contents.map((item) => (
        <li key={item.id}>
          <a href={getPlayerLink(instCode, isbn, item.page)}>{item.name}</a>
        </li>
      ))}
    </ol>
  );
};

export default EPubTableOfContents;
