import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

import { fetchTextbooks } from "../lib/elevateHelper";
import { Loadable, LoadableFactory } from "../lib/loadable";
import { Textbooks } from "./textbooksState";

export const fetchTextbooksThunk = createAsyncThunk<Textbooks, string>(
  "textbooks/fetchTextbooks",
  async (instCode: string, { rejectWithValue }) => {
    try {
      return await fetchTextbooks(instCode);
    } catch (error) {
      console.log(error);
      if (error instanceof AxiosError && error.request.status === 403) {
        return rejectWithValue("Not a catalyst customer");
      }
      if (error instanceof AxiosError && error.request.status === 404) {
        return rejectWithValue("No institution could be found");
      }
      return rejectWithValue("An unknown error occurred");
    }
  },
);

const initialState: Loadable<Textbooks> = LoadableFactory.reset();

const textbooksSlice = createSlice({
  name: "textbooks",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTextbooksThunk.pending, (state, action) => {
      return LoadableFactory.loading();
    });
    builder.addCase(fetchTextbooksThunk.fulfilled, (state, action) => {
      return LoadableFactory.loaded(action.payload);
    });
    builder.addCase(fetchTextbooksThunk.rejected, (state, action) => {
      return LoadableFactory.errored(action.payload);
    });
  },
});

export default textbooksSlice.reducer;
