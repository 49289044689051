import { Loadable } from "./loadable";

/**
 * Factory methods for quickly creating common types of Loadable states
 **/
export class LoadableFactory {
  /**
   * Create a Loadable mask without loading state or data
   *
   * @return {Loadable<T>} A Loadable representing the reset state.
   */
  static reset<T>(): Loadable<T> {
    return {
      data: undefined,
      isLoading: undefined,
      loadingError: undefined,
    };
  }

  /**
   * Create a Loadable that contains data, without error or loading state.
   *
   * @param {T} data - the data that has been loaded
   * @returns {Loadable<T>} A Loadable representing loaded data.
   **/
  static loaded<T>(data: T): Loadable<T> {
    return {
      data,
      isLoading: undefined,
      loadingError: undefined,
    };
  }

  /**
   * Create a Loadable representing the loading state, without data or error state.
   *
   * @returns {Loadable<T>} A Loadable representing the loading state
   **/
  static loading<T>(): Loadable<T> {
    return {
      data: undefined,
      isLoading: true,
      loadingError: undefined,
    };
  }

  /**
   * Create a Loadable representing the error state, without data or loading state.
   *
   * @param {Error} loadingError - the error encountered whilst loading
   * @returns {Loadable<unknown>} A Loadable representing the error state
   **/
  static errored<T>(loadingError: unknown): Loadable<T> {
    return {
      data: undefined,
      isLoading: undefined,
      loadingError,
    };
  }
}
