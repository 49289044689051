import axios, { AxiosResponse } from "axios";
import moment from "moment";

import {
  ElevateModule,
  ElevateModules,
} from "../FindModuleDialog/FindModuleDialog";
import { Textbooks } from "../TextbookBrowser";
import config from "../utils/config";

export interface CreateChapterOptions {
  isbn: string;
  title: string;
  description?: string;
  pageStartIndex: number;
  pageEndIndex: number;
  userGuid: string;
  moduleId: string;
}

type ModuleListItem = {
  id: string;
  attributes: {
    archivedAt: string | null;
    title: string;
    code: string;
    startDate: string;
  };
};

type EchoEvent = {
  props: Record<string, unknown>;
  class: string;
  source: string;
  timestamp: number;
  user: string;
};

type BaseEchoEventData = {
  userGuid: string;
  instShortCode: string;
};

export type SearchEventData = {
  searchedByISBN: boolean;
  searchQuery: string;
  hitCount: number;
} & BaseEchoEventData;

export const fetchModuleList = async (
  instShortCode: string,
  userGuid: string,
): Promise<{ data: ModuleListItem[] }> => {
  const url = `${config.elevateUrl}1/${instShortCode}/modules?filter[owner]=${userGuid}`;
  return axios
    .get<{ data: ModuleListItem[] }>(url)
    .then((response) => response.data);
};

type CreateChapterPost = {
  data: {
    type: string;
    attributes: {
      title: string;
      description: string | undefined;
      restrictedToPages: {
        start: number;
        end: number;
      };
    };
    relationships: {
      owner: {
        data: {
          id: string;
          type: string;
        };
      };
      modules: {
        data: {
          id: string;
          type: string;
        }[];
      };
    };
  };
};

type CreateChapterResponse = {
  data: {
    id: string;
  };
};

export const createChapter = (
  instShortCode: string,
  options: CreateChapterOptions,
): Promise<string> => {
  const {
    isbn,
    title,
    description,
    pageStartIndex,
    pageEndIndex,
    userGuid,
    moduleId,
  } = options;
  const url = `${config.elevateUrl}1/${instShortCode}/textbooks/${isbn}/chapters`;
  return axios
    .post<CreateChapterPost, AxiosResponse<CreateChapterResponse>>(
      url,
      {
        data: {
          type: "chapters",
          attributes: {
            title,
            description,
            restrictedToPages: {
              start: pageStartIndex,
              end: pageEndIndex,
            },
          },
          relationships: {
            owner: {
              data: {
                id: userGuid,
                type: "users",
              },
            },
            modules: {
              data: [
                {
                  id: moduleId,
                  type: "modules",
                },
              ],
            },
          },
        },
      },
      { headers: { "Content-Type": "application/vnd.api+json" } },
    )
    .then((response) => response.data.data.id);
};

export interface ContactUsData {
  fullName: string;
  jobTitle: string;
  department: string;
  university: string;
}

type PostContactUsData = {
  text: string;
};

export const postContactUsData = (
  formData: ContactUsData,
  instCode: string,
  emailAddress: string,
): Promise<AxiosResponse> => {
  const { fullName, jobTitle, department, university } = formData;

  const url = `${config.elevateUrl}contactform/catalystgetintouch`;
  const text = `
  Someone on Sage Catalyst would like to learn more about Talis Elevate

  name: ${fullName}
  institution: ${instCode}
  Email address: ${emailAddress}
  Job title: ${jobTitle}
  Department: ${department}
  University: ${university}
  `;

  return axios.post<PostContactUsData, never>(url, { text }).catch((error) => {
    return Promise.reject(error.response.data.error);
  });
};

export const filterArchivedModules = (modules: Array<ModuleListItem>) =>
  modules.filter(({ attributes: { archivedAt } }) => !archivedAt);

export const convertModuleListToElevateModules = (
  modules: Array<ModuleListItem>,
): ElevateModules => {
  return modules
    .map((module) => {
      const startDate = moment(module.attributes.startDate, moment.ISO_8601);
      return {
        title: module.attributes.title,
        code: module.attributes.code,
        startDate,
        id: module.id,
      };
    })
    .sort((a: ElevateModule, b: ElevateModule): number => {
      return a.startDate.isBefore(b.startDate) ? 1 : -1;
    });
};

export const fetchTextbooks = async (instCode: string): Promise<Textbooks> => {
  const url = `${config.elevateUrl}${instCode}/textbooks`;
  return axios.get<Textbooks>(url).then((response): Textbooks => response.data);
};

const sendEchoEvent = async (
  eventClass: string,
  props: Record<string, unknown>,
  userGuid: string,
): Promise<void> => {
  const url = `${config.elevateUrl}analytics/1/events`;
  const event: EchoEvent = {
    class: eventClass,
    props: props,
    source: `catalyst.${process.env.NODE_ENV}`,
    timestamp: moment().unix(),
    user: userGuid,
  };

  return axios.post(url, [event]);
};

export const sendSearchEvent = async (data: SearchEventData): Promise<void> => {
  sendEchoEvent(
    "catalyst.search",
    {
      inst: data.instShortCode,
      searchedByISBN: data.searchedByISBN,
      searchQuery: data.searchQuery,
      hitCount: data.hitCount,
    },
    data.userGuid,
  );
};

export type EPubTableOfContentsEntry = {
  id: string;
  name: string;
  page: number;
};

export const fetchEPubTableOfContents = async (
  instCode: string,
  isbn: string,
): Promise<EPubTableOfContentsEntry[]> => {
  const url = `${config.elevateUrl}1/${instCode}/textbooks/${isbn}/tableofcontents`;
  const response = await axios.get<EPubTableOfContentsEntry[]>(url);
  return response.data;
};

export const fetchInstitution = async (instCode: string) => {
  const url = `${config.elevateUrl}institutions/${instCode}`;
  const response = await axios.get(url);
  return response.data;
};

export type Recommendation = {
  isbn: string;
  score: number;
};

export const fetchRecommendedTitles = async (
  instCode: string,
  isbn: string,
) => {
  const url = `${config.elevateUrl}1/${instCode}/textbooks/${isbn}/recommendations`;
  const response = await axios.get<Recommendation[]>(url);
  return response.data.slice(0, 6);
};
