import { createSlice } from "@reduxjs/toolkit";

import { fetchAuthenticatedUserThunk } from "../../withAuth/authReducer";
import { UserStateOrNull, initialUserState } from "./userState";

const userSlice = createSlice({
  name: "user",
  initialState: initialUserState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchAuthenticatedUserThunk.fulfilled,
      (state: UserStateOrNull, action) => {
        const { guid, profile } = action.payload;
        let fullName = undefined;
        if (profile.firstName && profile.surname) {
          fullName = `${profile.firstName} ${profile.surname}`;
        }
        return {
          ...state,
          guid,
          profile,
          fullName,
        };
      },
    );
  },
});

export default userSlice.reducer;
