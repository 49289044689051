import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import GuardedComponent from "../GuardedComponent";
import PageNotFound from "../PageNotFound";
import TextbookBrowser from "../TextbookBrowser";
import FavouriteBooks from "../FavouriteBooks";
import config from "../utils/config";
import ViewTextbook from "../ViewTextbook";
import withAuth from "../withAuth";
import InstAwareRedirect from "./InstAwareRedirect";
import Categories from "../Categories";

const RequireAuth = withAuth(GuardedComponent);
const AppRouter = (): JSX.Element => {
  const location = useLocation();

  useEffect(() => {
    const pageUrl = `${location.pathname}${location.search}`;
    if (config.isProduction && typeof ineum !== "undefined") {
      ineum("page", pageUrl);
    }
  }, [location]);

  return (
    <Routes>
      <Route
        path="mybooks"
        element={
          <RequireAuth>
            <FavouriteBooks />
          </RequireAuth>
        }
      />
      <Route
        path="books/:isbn"
        element={
          <RequireAuth>
            <ViewTextbook />
          </RequireAuth>
        }
      />
      <Route
        path="books"
        element={
          <RequireAuth>
            <TextbookBrowser />
          </RequireAuth>
        }
      />
      <Route
        path="categories"
        element={
          <RequireAuth>
            <Categories />
          </RequireAuth>
        }
      />
      <Route
        path="/"
        element={
          <RequireAuth>
            <InstAwareRedirect to="/categories" />
          </RequireAuth>
        }
      />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default AppRouter;
