import { createSlice } from "@reduxjs/toolkit";

const numTextbooksInGridSlice = createSlice({
  name: "numTextbooksInGrid",
  initialState: 24,
  reducers: {
    incrementNumTextbooksInGrid(state: number) {
      return state + 24;
    },
  },
});

export const { incrementNumTextbooksInGrid } = numTextbooksInGridSlice.actions;

export default numTextbooksInGridSlice.reducer;
