import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchInstitution } from "../lib/elevateHelper";
import {
  fetchAuthenticatedUser,
  isElevateAcademic as isAcademic,
  userHasRightScopes,
} from "../lib/oauthHelper";
import { UserProfile } from "../redux/user";
import { AuthState, AuthStatus, initialAuthState } from "./authState";

export interface AuthenticatedUserPayload {
  instCode: string;
  guid: string;
  profile: UserProfile;
  auth: {
    scopes: string[];
    accessToken: string;
  };
  institution: {
    catalystPackages: string[];
    inactive: boolean;
  };
}

export interface InstitutionPayload {
  instCode: string;
  catalystPackages: string[];
  inactive: boolean;
}

export const fetchAuthenticatedUserThunk = createAsyncThunk(
  "auth/fetchAuthenticatedUser",
  async (instCode: string) => {
    const userJson = await fetchAuthenticatedUser();
    const {
      guid,
      oauth: { scope, access_token },
    } = userJson;

    const institution = await fetchInstitution(instCode);

    const payload: AuthenticatedUserPayload = {
      instCode: instCode,
      guid,
      profile: {
        email: userJson.profile?.email,
        firstName: userJson.profile?.first_name,
        surname: userJson.profile?.surname,
      },
      auth: {
        scopes: scope,
        accessToken: access_token,
      },
      institution: {
        catalystPackages: institution.catalystPackages,
        inactive: institution.inactive,
      },
    };
    return payload;
  },
);

const authSlice = createSlice({
  name: "auth",
  initialState: initialAuthState,
  reducers: {
    setCurrentAuthStatus(state: AuthState, action: PayloadAction<string>) {
      const currentAuthStatus = action.payload;
      return {
        ...initialAuthState,
        currentAuthStatus,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAuthenticatedUserThunk.pending, (state) => {
      return {
        ...state,
        currentAuthStatus: AuthStatus.NOT_CHECKED,
      };
    });
    builder.addCase(fetchAuthenticatedUserThunk.fulfilled, (state, action) => {
      const {
        instCode,
        auth: { accessToken, scopes },
        institution,
      } = action.payload;

      const unauthorisedInst =
        institution.catalystPackages.length < 1 || institution.inactive;

      const currentAuthStatus = unauthorisedInst
        ? AuthStatus.UNAUTHORISED_INST
        : userHasRightScopes(scopes, instCode)
          ? AuthStatus.AUTHORISED
          : AuthStatus.UNAUTHORISED;

      const isElevateAcademic = isAcademic(scopes);

      return {
        ...state,
        currentAuthStatus,
        isElevateAcademic,
        accessToken,
        scopes,
      };
    });
    builder.addCase(fetchAuthenticatedUserThunk.rejected, (state) => {
      return {
        ...state,
        currentAuthStatus: AuthStatus.UNAUTHENTICATED,
      };
    });
  },
});

export const { setCurrentAuthStatus } = authSlice.actions;

export default authSlice.reducer;
