import { ChangeEvent } from "react";

import { ContactUsData } from "../../lib/elevateHelper";
import { ReactComponent as TeachWithTalisElevate } from "./teachWithTalisElevate.svg";
import Modal from "react-bootstrap/Modal";

interface ContactUsFormProps {
  isOpen: boolean;
  onDismiss: CallableFunction;
  errorMessage?: string;
  formData: ContactUsData;
  isProcessing: boolean;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: CallableFunction;
}

const ContactUsFormDialog = ({
  errorMessage,
  formData,
  isProcessing,
  isOpen,
  onDismiss,
  handleChange,
  handleSubmit,
}: ContactUsFormProps): JSX.Element => {
  const { fullName, jobTitle, department, university } = formData;

  const isMissingRequiredField =
    !fullName || !jobTitle || !department || !university;

  return (
    <Modal
      tabIndex={-1}
      aria-labelledby="contactUsTitle"
      aria-hidden="true"
      show={isOpen}
      onHide={() => onDismiss()}
      size="lg"
      centered
      className="t-catalyst"
    >
      <Modal.Header className="text-center o-modal-header--no-border u-mt1 flex-column">
        <TeachWithTalisElevate />
        <h4 className="modal__title beta mt-2" id="contactUsTitle">
          Teach with Sage Catalyst
        </h4>
      </Modal.Header>
      <Modal.Body className="modal__body">
        <p className="modal__text">
          If you’re a social science faculty member, sign up to use Talis
          Elevate. This builds collaborative discussion capability into Sage
          textbook chapters, plus also within your own documents, media, images,
          and YouTube content, with analytical insight on student engagement.
          Fill in the form below and we will be in touch to set you up as soon
          as possible.
        </p>
        {errorMessage && (
          <p className="talis-alert-warning" role="alert">
            {errorMessage}
          </p>
        )}
        <form className="d-flex flex-column align-items-center">
          <div className="w-75">
            <div className="form-group">
              <label htmlFor="fullName">Full name:</label>
              <input
                required
                className="form-control"
                id="fullName"
                value={fullName}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="jobTitle">Job title:</label>
              <input
                required
                className="form-control"
                id="jobTitle"
                value={jobTitle}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="department">Department:</label>
              <input
                required
                className="form-control"
                id="department"
                value={department}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="university">University:</label>
              <input
                required
                className="form-control"
                id="university"
                value={university}
                onChange={handleChange}
              />
            </div>
          </div>
        </form>
        <p>
          By submitting this form, you agree to opt in (and/or resubscribe) to
          receive relevant messaging specific to this area of interest. Please
          refer to our{" "}
          <a
            href="https://us.sagepub.com/en-us/nam/privacy-policy"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>{" "}
          or{" "}
          <a
            href="https://uk.sagepub.com/en-gb/eur/contact-us"
            target="_blank"
            rel="noreferrer"
          >
            Contact Us
          </a>{" "}
          for more details.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-default"
          type="button"
          onClick={() => onDismiss()}
        >
          Cancel
        </button>
        <button
          onClick={() => handleSubmit()}
          className="btn btn-primary"
          disabled={isProcessing || isMissingRequiredField}
          type="submit"
        >
          Get in Touch
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ContactUsFormDialog;
