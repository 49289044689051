import { useLocation, useParams } from "react-router-dom";

import ErrorPage from "../ErrorPage";
import ExternalRedirect from "../ExternalRedirect";
import Loader from "../Loader";
import config from "../utils/config";
import { AuthStatus } from "../withAuth/authState";

type GuardedComponentProps = {
  children: JSX.Element;
  currentAuthStatus: string;
};

const GuardedComponent = ({
  children,
  currentAuthStatus,
}: GuardedComponentProps): JSX.Element => {
  const { instCode } = useParams();
  const { pathname } = useLocation();
  const currentLocation = `${config.hostUrl}${pathname.slice(1)}`;
  const loginUrl = `${config.elevateUrl}${instCode}/authenticate/${config.oauthAppId}?redirectUrl=${currentLocation}`;

  switch (currentAuthStatus) {
    case AuthStatus.NOT_CHECKED:
      return <Loader />;

    case AuthStatus.AUTHORISED:
      return children;

    case AuthStatus.UNAUTHORISED:
    case AuthStatus.UNAUTHENTICATED:
      return <ExternalRedirect to={loginUrl} />;

    case AuthStatus.UNAUTHORISED_INST:
      const error = Error("This institution no longer has access to Catalyst.");
      const message =
        "Contact your institution's IT department or library for more information.";

      return <ErrorPage error={error} additionalMessage={message} />;

    default:
      return <ErrorPage />;
  }
};

export default GuardedComponent;
