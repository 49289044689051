import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import PageNotFound from "../PageNotFound";
import { RootState } from "../redux/rootReducer";
import { Textbooks } from "../TextbookBrowser/textbooksState";
import ViewTextbook from "./ViewTextbook";

type ISBNRoute = {
  isbn: string;
  instCode: string;
};

interface ViewTextbookFromParamsProps {
  textbooks: Textbooks;
  isElevateAcademic: boolean;
}

const emptyArray: Textbooks = [];

const mapStateToProps = (state: RootState) => {
  return {
    textbooks: state.textbooks.data || emptyArray,
    isElevateAcademic: state.auth.isElevateAcademic,
  };
};

const ViewTextbookFromParams = (
  props: ViewTextbookFromParamsProps,
): JSX.Element => {
  const { isbn, instCode } = useParams() as ISBNRoute;
  const { textbooks, isElevateAcademic } = props;
  const textbookToShow = textbooks.find((textbook) => textbook.isbn === isbn);
  if (textbookToShow === undefined) {
    return <PageNotFound />;
  }
  return (
    <ViewTextbook
      {...{ instCode, textbook: textbookToShow, isElevateAcademic, textbooks }}
    />
  );
};

// eslint-disable-next-line react-redux/prefer-separate-component-file
export default connect(mapStateToProps)(ViewTextbookFromParams);
