import "./TableOfContents.scss";

import { TableOfContentsEntry } from "../TextbookBrowser";
import config from "../utils/config";

export type EmbedChapterCallback = (
  chapterTitle: string,
  startPageIndex: number,
  endPageIndex: number,
) => void;

export type TableOfContentsEntryWithPlayerLink = TableOfContentsEntry & {
  playerLink: string;
};

export type TableOfContentsWithPlayerLinks =
  Array<TableOfContentsEntryWithPlayerLink>;

export interface TableOfContentProps {
  contents: TableOfContentsWithPlayerLinks;
  totalPages: number;
  title: string;
  isbn: string;
  instCode: string;
  onEmbedChapter: EmbedChapterCallback;
}

const TextbookTableOfContents = (props: TableOfContentProps): JSX.Element => {
  const { contents, totalPages, title, isbn, instCode, onEmbedChapter } = props;

  let listItems;

  if (!contents || contents.length < 1) {
    listItems = (
      <li>
        <a href={`${config.elevateUrl}${instCode}/textbooks/${isbn}`}>
          {title} - Full text
        </a>
      </li>
    );
  } else {
    listItems = contents.map((entry, index) => {
      const nextEntry = contents[index + 1];
      const startPageIndex = entry.startPage;
      const endPageIndex = nextEntry ? nextEntry.startPage - 1 : totalPages - 1;
      return (
        <li key={index}>
          <a href={entry.playerLink}>{entry.title}</a>
          <button
            onClick={() =>
              onEmbedChapter(entry.title, startPageIndex, endPageIndex)
            }
            className="btn btn-link"
            type="submit"
          >
            <span className="visually-hidden">
              Add {entry.title} to Talis Elevate
            </span>
          </button>
        </li>
      );
    });
  }

  return <ol className="c-table-of-contents">{listItems}</ol>;
};

export default TextbookTableOfContents;
