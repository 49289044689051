export type ExternalRedirectProps = {
  to: string;
};

const ExternalRedirect = ({ to }: ExternalRedirectProps): null => {
  window.location.assign(to);
  return null;
};

export default ExternalRedirect;
