export interface PersonaUserProfile {
  first_name?: string;
  surname?: string;
  email?: string;
}

export interface PersonaUser {
  guid: string;
  oauth: {
    access_token: string;
    scope: string[];
  };
  profile?: PersonaUserProfile;
}

export type UserProfile = {
  firstName?: string;
  surname?: string;
  email?: string;
};

export type UserStateOrNull = {
  guid: string;
  profile?: UserProfile;
  fullName?: string;
} | null;

export const initialUserState: UserStateOrNull = null;
