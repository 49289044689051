import InstAwareLink from "../AppRouter/InstAwareLink";

const FavouriteBooksBlankSlate = () => {
  return (
    <div className="d-flex justify-content-center align-items-center flex-grow-1">
      <div className="text-center">
        <h2>Find your favourite books on this page</h2>
        <p className="pb-4 fs-3">
          You currently have no favourite books saved. Save your favourite books
          to this page by clicking the heart icon on the book.
        </p>
        <InstAwareLink to="/categories" className="btn btn-primary fs-3">
          See books
        </InstAwareLink>
      </div>
    </div>
  );
};

export default FavouriteBooksBlankSlate;
