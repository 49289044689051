import "./ViewTextbook.scss";

import { useState } from "react";

import InstAwareLink from "../AppRouter/InstAwareLink";
import ContactUs from "../ContactUs";
import EmbedChapter from "../EmbedChapter";
import { ChapterToEmbed } from "../EmbedChapter/EmbedChapter";
import EPubTableOfContents from "../EPubTableOfContents";
import { getAuthorsCitation } from "../lib/authorNamesHelper";
import {
  convertToSlug,
  getPublicationYear,
  getTableOfContentsWithLinks,
} from "../lib/textbooksHelper";
import TableOfContents from "../TableOfContents";
import { Textbook } from "../TextbookBrowser/textbooksState";
import TextbookImage from "../TextbookImage";
import RecommendedTitles from "../RecommendedTitles";
import SaveFavouriteBook from "./SaveFavouriteBook";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

export type ViewTextbookProps = {
  textbook: Textbook;
  instCode: string;
  isElevateAcademic: boolean;
  textbooks: Textbook[];
};

const DIALOGS = {
  EMBED_CHAPTER: "EMBED_CHAPTER",
  CONTACT_US: "CONTACT_US",
  CLOSED: "CLOSED",
};

const ViewTextbook = (props: ViewTextbookProps): JSX.Element => {
  const { textbook, instCode, isElevateAcademic, textbooks } = props;
  const [openedDialog, openDialog] = useState(DIALOGS.CLOSED);
  const [chapterToEmbed, setChapterToEmbed] = useState<ChapterToEmbed>();

  const close = () => openDialog(DIALOGS.CLOSED);
  const totalPages = parseInt(textbook.pageCount);

  const createDescriptionMarkup = () => {
    return { __html: textbook.sanitisedDescription };
  };

  const authors = [
    textbook.contributor1,
    textbook.contributor2,
    textbook.contributor3,
  ];

  const publicationYear = getPublicationYear(textbook);

  const tableOfContentsWithPlayerLink = getTableOfContentsWithLinks(
    instCode,
    textbook,
  );

  const onEmbedChapter = (
    chapterTitle: string,
    startPage: number,
    endPage: number,
  ) => {
    if (isElevateAcademic) {
      setChapterToEmbed({
        title: chapterTitle,
        startPageIndex: startPage,
        endPageIndex: endPage,
      });
      openDialog(DIALOGS.EMBED_CHAPTER);
      return;
    }
    openDialog(DIALOGS.CONTACT_US);
    return;
  };

  const newEditionAvailable = textbooks.some(
    ({ isbn }) => isbn === textbook.newEditionIsbn,
  );

  const ePubIsbnNumber: string | undefined = textbook.epub3 || textbook.epub2;

  return (
    <div
      className={
        textbook.newEditionIsbn && newEditionAvailable
          ? "o-faux-header-alert flex-grow-1"
          : "o-faux-header flex-grow-1"
      }
    >
      <div className="wrapper pt-5">
        {textbook.newEditionIsbn && newEditionAvailable && (
          <div className="talis-alert-warning pt-3 pb-2 px-4 m-0 text-black">
            <p>
              <strong>
                There is a new edition available for this textbook.
              </strong>{" "}
              <InstAwareLink to={`/books/${textbook.newEditionIsbn}`}>
                View latest edition
              </InstAwareLink>
            </p>
            <p>
              From the <time dateTime="2024-06-01">1st June 2024</time> this
              older edition will be withdrawn from Catalyst. Further information
              is available on{" "}
              <a href="https://catalystsolutions.sagepub.com/support/solutions/articles/7000074183-when-are-new-editions-added-">
                Catalyst support
              </a>
            </p>
          </div>
        )}
        <section className="c-book-listing">
          <header className="c-book-listing__meta">
            <InstAwareLink
              to={"/books?category=" + convertToSlug(textbook.category)}
              className="d-inline-block mb-3 lh-2 fs-2"
            >
              {textbook.category}
            </InstAwareLink>
            <h1 className="mt-0 mb-3 fw-semibold lh-2">{textbook.title}</h1>
            <span className="d-inline-block mb-3">
              {getAuthorsCitation(authors)}
            </span>
            <SaveFavouriteBook isbn={textbook.isbn} instCode={instCode} />
          </header>

          <figure>
            <TextbookImage isbn={textbook.isbn} width={347} />
            <figcaption className="text-black">
              <div
                className="mb-3"
                dangerouslySetInnerHTML={createDescriptionMarkup()}
              />
              <dl className="d-flex flex-column">
                <div className="d-flex flex-row">
                  <dt className="me-2">Year</dt>
                  <dd>{publicationYear}</dd>
                </div>
                <div className="d-flex flex-row">
                  <dt className="me-2">
                    <abbr title="International Standard Book Number">ISBN</abbr>
                  </dt>
                  <dd>{textbook.isbn}</dd>
                </div>
              </dl>
            </figcaption>
          </figure>
          <div>
            <Tabs
              defaultActiveKey={ePubIsbnNumber ? "sage-catalyst" : undefined}
              activeKey={!ePubIsbnNumber ? "talis-elevate" : undefined}
              id="textbook-tabs-tabs"
            >
              {ePubIsbnNumber && (
                <Tab eventKey="sage-catalyst" title="Read with Sage Catalyst">
                  <EPubTableOfContents
                    instCode={instCode}
                    isbn={ePubIsbnNumber}
                  />
                </Tab>
              )}
              <Tab eventKey="talis-elevate" title="Teach via Talis Elevate">
                <div
                  className="talis-alert-info d-flex text-black align-items-center py-3 px-4 mb-4"
                  role="alert"
                >
                  <p>
                    As part of your university&apos;s access to Sage Catalyst,
                    faculty members can sign up to use Talis Elevate.
                  </p>
                  <button
                    type="button"
                    className="btn btn-primary ms-5"
                    style={{ width: "8rem", fontSize: "1rem" }}
                    onClick={() => {
                      openDialog(DIALOGS.CONTACT_US);
                    }}
                  >
                    Sign up{" "}
                    <span className="visually-hidden">for Talis Elevate</span>
                  </button>
                </div>
                <TableOfContents
                  contents={tableOfContentsWithPlayerLink}
                  totalPages={totalPages}
                  title={textbook.title}
                  onEmbedChapter={onEmbedChapter}
                />
                <EmbedChapter
                  instCode={instCode}
                  isbn={textbook.isbn}
                  isElevateAcademic={isElevateAcademic}
                  onDismiss={close}
                  isOpen={openedDialog === DIALOGS.EMBED_CHAPTER}
                  chapterToEmbed={chapterToEmbed}
                  textbook={textbook}
                />
                <ContactUs
                  isOpen={openedDialog === DIALOGS.CONTACT_US}
                  onDismiss={close}
                />
              </Tab>
            </Tabs>
          </div>
          <RecommendedTitles instCode={instCode} isbn={textbook.isbn} />
        </section>
      </div>
    </div>
  );
};

export default ViewTextbook;
