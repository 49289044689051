import "./AddToModuleSuccess.scss";

import { ReactComponent as Tick } from "./tick.svg";
import Modal from "react-bootstrap/Modal";

export type AddToModuleSuccessProps = {
  isOpen: boolean;
  chapterTitle: string;
  textbookTitle: string;
  moduleTitle: string;
  onDismiss: CallableFunction;
  viewResourceUrl: string;
};

const AddToModuleSuccess = ({
  isOpen,
  chapterTitle,
  textbookTitle,
  moduleTitle,
  onDismiss,
  viewResourceUrl,
}: AddToModuleSuccessProps): JSX.Element => {
  return (
    <Modal
      aria-label="Added to module successfully"
      show={isOpen}
      onHide={() => onDismiss()}
      className="t-catalyst"
      size="lg"
    >
      <Modal.Header className="modal-header text-center o-modal-header--no-border u-mt1 flex-column pb-1">
        <Tick className="c-success-tick" />
        <h4 className="modal__title beta">All done</h4>
      </Modal.Header>
      <Modal.Body className="modal__body c-add-module-success">
        <p className="modal__text" data-testid="qa-add-to-module-success">
          Chapter <strong>{chapterTitle}</strong> from{" "}
          <strong>{textbookTitle}</strong> has been added to{" "}
          <strong>{moduleTitle}</strong> in Talis Elevate
        </p>
      </Modal.Body>
      <Modal.Footer>
        <button
          onClick={() => onDismiss()}
          className="btn btn-default"
          type="button"
        >
          Close
        </button>
        <a className="btn btn-primary" href={viewResourceUrl}>
          View Resource
        </a>
      </Modal.Footer>
    </Modal>
  );
};

export default AddToModuleSuccess;
