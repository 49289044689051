import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { BaseQueryFn } from "@reduxjs/toolkit/query/react";

export type BaseQueryError = {
  status: number;
  data: unknown;
};

const axiosBaseQuery: BaseQueryFn<
  AxiosRequestConfig,
  unknown,
  BaseQueryError
> = async (requestConfig) => {
  try {
    const result = await axios.request(requestConfig);
    return { data: result.data };
  } catch (error) {
    const axiosError = error as AxiosError;
    return {
      error: {
        status: axiosError.response?.status || 0,
        data: axiosError.response?.data,
      },
    };
  }
};

export default axiosBaseQuery;
