import "./TextbookListItem.scss";

import { getAuthorsCitation } from "../lib/authorNamesHelper";
import { getPublicationYear } from "../lib/textbooksHelper";
import TextbookImage from "../TextbookImage";

type TextBookListItem = {
  title: string;
  authors: string[];
  isbn: string;
  publicationDate: string;
};

export type TextbookListItemProps = {
  textbook: TextBookListItem;
};

const TextbookListItem = (props: TextbookListItemProps): JSX.Element => {
  const { title, authors, isbn } = props.textbook;
  const formattedAuthors = getAuthorsCitation(authors);
  const publicationYear = getPublicationYear(props.textbook);

  return (
    <div className="position-relative p-1 h-100 textbooksearch">
      <TextbookImage isbn={isbn} width={156} />
      <div className="c-textbook textbookinfo text-wrap">
        <p className="fw-bold">{title}</p>
        <ul className="c-textbook__meta list-unstyled fw-medium">
          <li className="category-authors">By {formattedAuthors}</li>
          <li className="c-textbook__year">{publicationYear}</li>
        </ul>
      </div>
    </div>
  );
};

export default TextbookListItem;
