import Modal from "react-bootstrap/Modal";
import { Moment } from "moment";
import { useEffect, useReducer } from "react";

import performSearch, { Searchable } from "../lib/search";
import reducer, {
  FindModuleDialogActionType,
  INITIAL_STATE,
  RESET_ACTION,
} from "./findModuleDialog.reducer";
import ModuleSearch from "./ModuleSearch";

export interface ElevateModule {
  id: string;
  title: string;
  code: string;
  startDate: Moment;
}

export type ElevateModules = Array<ElevateModule>;

interface SearchableElevateModule extends ElevateModule, Searchable {}

export type FindModuleDialogProps = {
  isOpen: boolean;
  isProcessing: boolean;
  errorMessage?: string;
  modules: ElevateModules;
  chapterTitle: string;
  textbookTitle: string;
  onDismiss: CallableFunction;
  onSelectModule: CallableFunction;
};

const FindModuleDialog = ({
  isOpen,
  isProcessing,
  modules,
  chapterTitle,
  textbookTitle,
  onDismiss,
  onSelectModule,
  errorMessage,
}: FindModuleDialogProps): JSX.Element => {
  const [state, dispatcher] = useReducer(reducer, INITIAL_STATE);
  const { searchValue, selectedModuleId } = state;
  const searchableModules = modules.map(
    ({
      id,
      title,
      code,
      startDate,
    }: ElevateModule): SearchableElevateModule => ({
      id,
      title,
      code,
      startDate,
    }),
  );
  const moduleSearch = performSearch(searchableModules, {
    fields: ["title", "code"],
    limit: 20,
  });
  const results = moduleSearch(searchValue);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target !== undefined) {
      dispatcher({
        type: FindModuleDialogActionType.SEARCH,
        payload: {
          searchValue: event.target.value,
        },
      });
    }
  };

  useEffect(() => {
    dispatcher(RESET_ACTION);
  }, [isOpen]);
  return (
    <Modal
      aria-label="Search for a module to add the chapter to"
      show={isOpen}
      onHide={() => onDismiss()}
      className="t-catalyst"
      centered
      size="lg"
    >
      <Modal.Header className="text-center o-modal-header--no-border u-mt1 my-4">
        <h4 className="modal__title beta">Find a module</h4>
      </Modal.Header>
      <Modal.Body className="modal__body">
        <p className="modal__text">
          You have selected Chapter <strong>{chapterTitle}</strong> from{" "}
          <strong>{textbookTitle}</strong>
        </p>
        {errorMessage ? (
          <p className="talis-alert-warning p-2" role="alert">
            {errorMessage}
          </p>
        ) : null}
        {modules.length === 0 ? (
          <p className="modal__text" data-testid="qa-no-user-modules">
            You don&apos;t seem to have any modules, please check on Talis
            Elevate and raise a support ticket if this issue persists
          </p>
        ) : (
          <ModuleSearch
            handleSearchChange={handleSearchChange}
            modules={modules}
            results={results}
            searchValue={searchValue}
            selectedModule={selectedModuleId}
            selectModule={(moduleId: string) => {
              dispatcher({
                type: FindModuleDialogActionType.SELECT_MODULE,
                payload: { selectedModuleId: moduleId },
              });
            }}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <button
          onClick={() => onDismiss()}
          className="btn btn-default"
          type="button"
        >
          Cancel
        </button>
        <button
          onClick={() => {
            const module = modules.find(
              (module) => module.id === selectedModuleId,
            );
            onSelectModule(module);
          }}
          className="btn btn-primary"
          disabled={isProcessing || !Boolean(selectedModuleId)}
          type="submit"
        >
          {isProcessing ? "Adding..." : "Add to module"}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default FindModuleDialog;
