import config from "../utils/config";

export const generateUrl = (
  path: string,
  params?: Record<string, string | number | boolean | undefined>,
  rootPath = config.elevateUrl,
): string => {
  const url = new URL(path, rootPath);
  if (params) {
    for (const property in params) {
      const value = params[property];
      if (value !== undefined) {
        url.searchParams.append(property, value.toString());
      }
    }
  }
  return url.toString();
};
