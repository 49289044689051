import { Navigate } from "react-router-dom";

import ExternalRedirect from "../ExternalRedirect";
import { getInstCodeFromScopes } from "../lib/oauthHelper";
import config from "../utils/config";
import { withAuthProps } from "../withAuth";

interface RedirectToSageOrHomeProps extends withAuthProps {
  scopes: Array<string>;
}

const RedirectToSageOrHome = ({
  scopes,
}: RedirectToSageOrHomeProps): JSX.Element => {
  const instCode = getInstCodeFromScopes(scopes);

  if (instCode) {
    return <Navigate to={`/${instCode}/books`} />;
  }

  return <ExternalRedirect to={config.sageMarketingUrl} />;
};

export default RedirectToSageOrHome;
