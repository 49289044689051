import { Menu, MenuItem, MenuProps } from "react-bootstrap-typeahead";
import { Option } from "react-bootstrap-typeahead/types/types";
import { SearchableTextbook } from "../../TextbookBrowser";
import TextbookListItem from "../../TextbookListItem";
import EmptySearchResult from "../EmptySearchResult";

interface TextbookSearchMenuProps {
  options: Option[];
  menuProps: MenuProps;
  value: string;
  maxResults: number | undefined;
}

const generateSearchResultItems = (
  textbooks: SearchableTextbook[],
  limit: number,
): SearchableTextbook[] => {
  if (textbooks === undefined || !textbooks.length) {
    return [];
  }
  return textbooks.slice(0, limit);
};

const TextbookSearchMenu = ({
  options,
  menuProps,
  value,
  maxResults,
}: TextbookSearchMenuProps) => {
  const books = options as SearchableTextbook[];
  const limitedBooks = generateSearchResultItems(books, maxResults || 20);
  const renderedBooks = limitedBooks.map((book: SearchableTextbook, index) => {
    const props = {
      title: book.title,
      isbn: book.isbn,
      authors: [book.contributor1, book.contributor2, book.contributor3],
      publicationDate: book.publicationDate,
    };
    return (
      <MenuItem
        key={book.isbn}
        option={book}
        position={index}
        value={`${book.title} (ISBN: ${book.isbn})`}
      >
        <TextbookListItem textbook={props} />
      </MenuItem>
    );
  });
  return (
    <Menu
      id="textbook-search-results"
      className="w-100 textbook-search-results"
      maxHeight={menuProps.maxHeight}
      emptyLabel={<EmptySearchResult search={value} />}
    >
      {renderedBooks}
    </Menu>
  );
};

export default TextbookSearchMenu;
