import { connect } from "react-redux";

import { RootState } from "../redux/rootReducer";
import withAuth from "../withAuth";
import RedirectToSageOrHome from "./RedirectToSageOrHome";

const AuthenticatedRedirectToSageOrHome = withAuth(RedirectToSageOrHome);

const mapStateToProps = (state: RootState) => {
  return {
    scopes: state.auth.scopes,
  };
};

const connector = connect(mapStateToProps);

export default connector(AuthenticatedRedirectToSageOrHome);
