import "../TextbookBrowser.scss";
import { SortByDropdownProps, TextbookSortBy } from ".";

const SortByDropdown = ({
  selectedSortBy,
  onSortSelect,
}: SortByDropdownProps): JSX.Element => {
  const changeSortBy = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const chosenSortBy = e.target.value as TextbookSortBy;
    onSortSelect(chosenSortBy);
  };

  return (
    <>
      <label
        htmlFor="sortBy"
        className="u-pl1 text-start sort-by-dropdown-text-size"
      >
        <strong>Sort by:</strong>
      </label>
      <select
        className="btn category-dropdown-text-size"
        onChange={(e) => changeSortBy(e)}
        id="sortBy"
        value={selectedSortBy}
      >
        <option value={TextbookSortBy.PUBLICATION_DATE}>
          Publication date
        </option>
        <option value={TextbookSortBy.ALPHABETICAL}>Alphabetical</option>
      </select>
    </>
  );
};

export default SortByDropdown;
