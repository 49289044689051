import { Navigate, useParams } from "react-router-dom";

export type InstAwareRedirectProps = {
  to: string;
};

const InstAwareRedirect = (props: InstAwareRedirectProps): JSX.Element => {
  const { instCode } = useParams() as { instCode: string };
  const { to } = props;
  const target = instCode ? `/${instCode}${to}` : to;

  return <Navigate to={target} />;
};

export default InstAwareRedirect;
