export interface FindModuleDialogState {
  searchValue: string;
  selectedModuleId: string;
}

export enum FindModuleDialogActionType {
  RESET = "RESET",
  SEARCH = "SEARCH",
  SELECT_MODULE = "SELECT_MODULE",
}

export type FindModuleDialogAction =
  | { type: FindModuleDialogActionType.RESET }
  | {
      type: FindModuleDialogActionType.SEARCH;
      payload: { searchValue: string };
    }
  | {
      type: FindModuleDialogActionType.SELECT_MODULE;
      payload: { selectedModuleId: string };
    };

export const INITIAL_STATE: FindModuleDialogState = {
  searchValue: "",
  selectedModuleId: "",
};

export const RESET_ACTION: FindModuleDialogAction = {
  type: FindModuleDialogActionType.RESET,
};

const reducer = (
  state: FindModuleDialogState,
  action: FindModuleDialogAction,
): FindModuleDialogState => {
  switch (action.type) {
    case FindModuleDialogActionType.RESET:
      return INITIAL_STATE;
    case FindModuleDialogActionType.SEARCH:
      return {
        ...state,
        searchValue: action.payload.searchValue,
      };
    case FindModuleDialogActionType.SELECT_MODULE:
      return {
        ...state,
        selectedModuleId: action.payload.selectedModuleId,
      };
  }
};

export default reducer;
