import { useEffect } from "react";
import { useParams, NavLink } from "react-router-dom";

import AppRouter from "../AppRouter";
import InstAwareLink from "../AppRouter/InstAwareLink";
import ErrorPage from "../ErrorPage";
import ExternalRedirect from "../ExternalRedirect";
import Loader from "../Loader";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { RootState } from "../redux/rootReducer";
import catalyst from "../Sage_logo_catalyst.svg";
import { fetchTextbooksThunk } from "../TextbookBrowser";
import config from "../utils/config";

const DiscoveryLayer = (): JSX.Element => {
  const { instCode } = useParams() as { instCode: string };
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchTextbooksThunk(instCode));
  }, [dispatch, instCode]);

  const selectTextbooks = (state: RootState) => state.textbooks;
  const { isLoading, loadingError, data } = useAppSelector(selectTextbooks);

  if (loadingError) {
    if (
      loadingError === "Not a catalyst customer" ||
      loadingError === "No institution could be found"
    ) {
      return <ExternalRedirect to={config.sageMarketingUrl} />;
    }
    return <ErrorPage />;
  }

  if (isLoading || !data) {
    return <Loader />;
  }

  return (
    <div className="App t-catalyst">
      <a
        className="visually-hidden u-sr-only--focusable c-skip-link"
        href="#main-content"
      >
        Skip to content
      </a>
      <header className="c-banner">
        <div className="wrapper c-banner__inner discovery-hover-underline">
          <InstAwareLink to="/categories">
            <img
              src={catalyst}
              width="300"
              height="48"
              alt=""
              className="o-brand"
            />
            <h1 className="visually-hidden">Sage Catalyst</h1>
          </InstAwareLink>
          <div className="inner-wrapper">
            <NavLink
              to={instCode ? `/${instCode}/mybooks` : "/mybooks"}
              className={({ isActive }) =>
                isActive
                  ? "c-banner__favourite-link c-banner__favourite-link--active text-black"
                  : "c-banner__favourite-link text-black"
              }
            >
              Favourites
            </NavLink>
          </div>
        </div>
      </header>
      <main id="main-content" className="d-flex flex-grow-1">
        <AppRouter />
      </main>
      <footer>
        <ul className="list-unstyled m-0 p-0 d-flex justify-content-center discovery-hover-underline">
          <li className="mb-2 px-3">
            <a href="https://talis.com/legal">Legal</a>
          </li>
          <li className="mb-2 px-3">
            <a href="https://talis.com/gdpr">GDPR</a>
          </li>
          <li className="mb-2 px-3">
            <a href="https://uk.sagepub.com/sites/default/files/sage_catalyst_aspire_audit.pdf">
              Accessibility
            </a>
          </li>
          <li className="mb-2 px-3">
            <a href="http://catalystsolutions.sagepub.com/support/home">
              Support
            </a>
          </li>
          <li className="mb-2 px-3">
            <a href="https://sagecatalystfeedbackportal.ideas.aha.io/ideas/new">
              Give feedback
            </a>
          </li>
        </ul>
      </footer>
    </div>
  );
};

export default DiscoveryLayer;
