import "./GridListView.scss";

export type GridListViewProps = {
  children: Array<JSX.Element>;
  numItemsToRender: number;
};

const GridListView = (props: GridListViewProps): JSX.Element => {
  const { children, numItemsToRender } = props;
  const limitedChildren = children.filter(
    (_, index) => index < numItemsToRender,
  );
  return (
    <section className="o-gridlist-view">
      <ol>{limitedChildren}</ol>
    </section>
  );
};

export default GridListView;
