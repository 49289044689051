import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faScrollOld,
  faBriefcase,
  faUsers,
  faBadgeSheriff,
  faUserFriends,
  faHandsHelping,
  faUserNurse,
  faSearch,
  faMedkit,
  faHandshake,
  faHeadSideBrain,
  faHeartbeat,
  faBooks,
  faBallPile,
  faUniversity,
} from "@fortawesome/pro-light-svg-icons";
import { faChartColumn, faTrees } from "@fortawesome/sharp-light-svg-icons";
import { faCommentAlt, faHeart } from "@fortawesome/pro-regular-svg-icons";
import { faHeart as fasHeart } from "@fortawesome/pro-solid-svg-icons";
library.add(
  faScrollOld,
  faBriefcase,
  faUsers,
  faCommentAlt,
  faBadgeSheriff,
  faUserFriends,
  faChartColumn,
  faUniversity,
  faTrees,
  faHandsHelping,
  faUserNurse,
  faSearch,
  faMedkit,
  faHandshake,
  faHeadSideBrain,
  faHeartbeat,
  faBooks,
  faBallPile,
  faHeart,
  fasHeart,
);
