import "./SaveFavouriteBook.scss";
import classNames from "classnames";

import {
  upsertFavouriteBooks,
  downsertFavouriteBooks,
  useDeleteFavouriteBookMutation,
  useFetchFavouriteBooksQuery,
  useSaveFavouriteBookMutation,
} from "../../api/favouriteBooksApiSlice";
import { useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppDispatch } from "../../redux/hooks";
import { skipToken } from "@reduxjs/toolkit/query/react";

export type SaveFavouriteBookProps = {
  userGuid: string;
  isbn: string;
  instCode: string;
};

const SaveFavouriteBook = ({
  userGuid,
  isbn,
  instCode,
}: SaveFavouriteBookProps): JSX.Element => {
  const [isMakingRequest, setIsMakingRequest] = useState(false);
  const [saveFavouriteBook] = useSaveFavouriteBookMutation();
  const [deleteFavouriteBook] = useDeleteFavouriteBookMutation();
  const {
    data: favouriteIsbns,
    error,
    isLoading,
  } = useFetchFavouriteBooksQuery(
    userGuid !== "" ? { instCode, userGuid } : skipToken,
  );
  const dispatch = useAppDispatch();
  const onclick = async () => {
    setIsMakingRequest(true);
    try {
      if (!isSaved) {
        await saveFavouriteBook({
          instCode,
          isbn,
        });

        await dispatch(upsertFavouriteBooks({ instCode, userGuid }, isbn));
      } else {
        await deleteFavouriteBook({
          instCode,
          isbn,
        });
        await dispatch(downsertFavouriteBooks({ instCode, userGuid }, isbn));
      }
    } finally {
      setIsMakingRequest(false);
    }
  };

  const isSaved = useMemo(() => {
    if (!favouriteIsbns) {
      return false;
    }
    return favouriteIsbns.includes(isbn);
  }, [favouriteIsbns, isbn]);

  if (error) {
    console.error("An error occurred loading favourite books", error);
    return <></>;
  }

  return (
    <button
      type="button"
      className={classNames(
        "save-book__container d-flex flex-wrap border-box mb-3 border-0 bg-transparent",
        {
          disabled: isLoading || isMakingRequest,
        },
      )}
      onClick={() => onclick()}
    >
      <div>
        <FontAwesomeIcon
          icon={[!isSaved ? "far" : "fas", "heart"]}
          style={{ color: !isSaved ? "#195bc6" : "red" }}
        />
      </div>
      <div className="save-book__text ps-2 m-0">
        {!isSaved ? "Add to favourites" : "Remove from favourites"}
      </div>
    </button>
  );
};

export default SaveFavouriteBook;
