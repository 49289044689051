import "../TextbookBrowser.scss";
import { TextbookCategoryWithCount } from "../../lib/textbooksHelper";

export interface CategoryDropdownProps {
  selectedCategory: string;
  allCategories: TextbookCategoryWithCount[];
  onNavigate: (category?: TextbookCategoryWithCount) => void;
  totalTextbooks: number;
}

const CategoryDropdown = ({
  selectedCategory,
  allCategories,
  onNavigate,
  totalTextbooks,
}: CategoryDropdownProps): JSX.Element => {
  const changeCategory = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCategoryTitle = e.target.value;
    if (selectedCategoryTitle) {
      const category = allCategories.find(
        (category) => category.title === selectedCategoryTitle,
      );
      onNavigate(category);
    }
  };
  return (
    <select
      className="btn btn-default category-dropdown-text-size text-start"
      id="categoryList"
      onChange={(e) => changeCategory(e)}
      aria-label="Discipline List"
      value={selectedCategory}
    >
      <option value="" disabled>
        Discipline
      </option>
      <option value="all">All books {`(${totalTextbooks})`}</option>
      {allCategories.map((category) => {
        const { title, bookCount } = category;
        return (
          <option value={title} key={title}>
            {title}
            {` (${bookCount})`}
          </option>
        );
      })}
    </select>
  );
};

export default CategoryDropdown;
