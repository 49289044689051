type AppConfig = {
  elevateUrl: string;
  oauthUrl: string;
  oauthAppId: string;
  hostUrl: string;
  sageMarketingUrl: string;
  isProduction: boolean;
};

enum Environment {
  Local = "local",
  Production = "production",
  Staging = "staging",
}

const getEnvironment = (hostUrl: string): Environment => {
  if (hostUrl === "https://catalyst.talis.com/") {
    return Environment.Production;
  }

  if (hostUrl.includes("staging-catalyst")) {
    return Environment.Staging;
  }

  return Environment.Local;
};

const getConfig = (): AppConfig => {
  const hostUrl = document.location.origin + "/";
  const environment = getEnvironment(hostUrl);

  switch (environment) {
    case Environment.Local:
      return {
        elevateUrl: "https://elevate.talis.local/",
        oauthUrl: "https://persona.talis.local/",
        oauthAppId: "catalyst-local-app",
        hostUrl: "https://catalyst.talis.local:3005/",
        sageMarketingUrl: "https://catalyst.talis.local:3005/talis",
        isProduction: false,
      };

    case Environment.Production:
      return {
        elevateUrl: "https://app.talis.com/",
        oauthUrl: "https://users.talis.com/",
        oauthAppId: "catalyst-prod-app",
        hostUrl: "https://catalyst.talis.com/",
        sageMarketingUrl:
          "https://uk.sagepub.com/en-gb/eur/sage-catalyst-book-list",
        isProduction: true,
      };

    case Environment.Staging:
      return {
        elevateUrl: "https://app.talis.com/",
        oauthUrl: "https://users.talis.com/",
        oauthAppId: "catalyst-staging-app",
        hostUrl: `${document.location.origin}/`,
        sageMarketingUrl:
          "https://uk.sagepub.com/en-gb/eur/sage-catalyst-book-list",
        isProduction: false,
      };
  }
};

const config = getConfig();

export default config;
