import RecommendedTitles from "./RecommendedTitles";

import { connect } from "react-redux";

import { RootState } from "../redux/rootReducer";

const mapStateToProps = (state: RootState) => {
  return {
    textbooks: state.textbooks.data || [],
  };
};

export default connect(mapStateToProps)(RecommendedTitles);
