import { ReactComponent as Search } from "../search-light.svg";

interface EmptySearchResultProps {
  search: string;
}

const EmptySearchResult = ({ search }: EmptySearchResultProps) => {
  return (
    <div
      className="no-search-results d-flex flex-column align-items-center justify-content-evenly text-black"
      data-testid="no-matches-found"
    >
      <div className="icon-border rounded-circle" aria-hidden="true">
        <Search className="icon" />
      </div>
      <p className="no-search-result-text">
        No results matching &apos;{search}&apos;
      </p>
    </div>
  );
};

export default EmptySearchResult;
