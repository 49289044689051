import "./FavouriteBooks.scss";

import { Textbook, Textbooks } from "../TextbookBrowser/textbooksState";

import GridListView from "../GridListView";
import TextbookListItem from "../TextbookListItem";
import { useFetchFavouriteBooksQuery } from "../api/favouriteBooksApiSlice";
import { useParams } from "react-router-dom";
import SortByDropdown, {
  TextbookSortBy,
} from "../TextbookBrowser/SortByDropdown";
import { sortTextbooks } from "../lib/textbooksHelper";
import { useMemo, useState } from "react";
import FavouriteBooksBlankSlate from "../FavouriteBooksBlankSlate";
import InstAwareLink from "../AppRouter/InstAwareLink";
import { skipToken } from "@reduxjs/toolkit/query/react";

export type FavouriteBooksProps = {
  textbooks: Textbooks;
  userGuid: string;
};

const generateTextbookListItems = (
  textbooks: Textbooks,
  sortBy: TextbookSortBy,
) => {
  const textbookListItems = textbooks
    .sort((a, b) => sortTextbooks(a, b, sortBy))
    .map((textbook: Textbook) => {
      const { title, isbn, publicationDate } = textbook;
      const textbookListItem = {
        title,
        authors: [
          textbook.contributor1,
          textbook.contributor2,
          textbook.contributor3,
        ],
        isbn,
        publicationDate,
      };
      return (
        <li key={isbn}>
          <InstAwareLink to={`/books/${isbn}`} className="textbook-item-text">
            <TextbookListItem textbook={textbookListItem} />
          </InstAwareLink>
        </li>
      );
    });
  return textbookListItems;
};

const FavouriteBooks = ({
  textbooks,
  userGuid,
}: FavouriteBooksProps): JSX.Element => {
  const { instCode } = useParams() as { instCode: string };
  const [selectedSortBy, setSelectedSortBy] = useState(
    TextbookSortBy.PUBLICATION_DATE,
  );
  const {
    data: favouriteIsbns,
    error,
    isLoading,
  } = useFetchFavouriteBooksQuery(
    userGuid !== "" ? { instCode, userGuid } : skipToken,
  );

  const favouriteBooks = useMemo(() => {
    if (!favouriteIsbns) {
      return false;
    } else {
      return textbooks.filter((textbook) =>
        favouriteIsbns.includes(textbook.isbn),
      );
    }
  }, [favouriteIsbns, textbooks]);
  const favouriteBooksListItems = useMemo(() => {
    if (!favouriteBooks) {
      return false;
    } else {
      return generateTextbookListItems(favouriteBooks, selectedSortBy);
    }
  }, [favouriteBooks, selectedSortBy]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return (
      <div className="talis-alert-warning text-black p-4" role="alert">
        An error occurred loading favourite books.
      </div>
    );
  }

  if (!favouriteIsbns || !favouriteBooksListItems) {
    return <></>;
  }

  if (favouriteIsbns.length === 0 || favouriteBooksListItems.length === 0) {
    return <FavouriteBooksBlankSlate />;
  }

  return (
    <div className="wrapper px-4">
      <div className="pt-3">
        <SortByDropdown
          selectedSortBy={selectedSortBy}
          onSortSelect={setSelectedSortBy}
        />
      </div>
      <div className="book-display__a-tag">
        <GridListView numItemsToRender={favouriteIsbns.length}>
          {favouriteBooksListItems}
        </GridListView>
      </div>
    </div>
  );
};

export default FavouriteBooks;
