const Loader = (): JSX.Element => (
  <div className="loading-indicator" role="status" aria-live="polite">
    <div className="cradle">
      <div className="cradle-node">
        <div className="cradle-ball"></div>
      </div>
      <div className="cradle-node">
        <div className="cradle-ball"></div>
      </div>
      <div className="cradle-node">
        <div className="cradle-ball"></div>
      </div>
      <div className="cradle-node">
        <div className="cradle-ball"></div>
      </div>
    </div>

    <span className="visually-hidden">Loading</span>
  </div>
);

export default Loader;
