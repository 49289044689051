export enum AuthStatus {
  NOT_CHECKED = "NOT_CHECKED",
  AUTHORISED = "AUTHORISED",
  UNAUTHORISED = "UNAUTHORISED",
  UNAUTHORISED_INST = "UNAUTHORISED_INST",
  UNAUTHENTICATED = "UNAUTHENTICATED",
}

export interface AuthState {
  currentAuthStatus: string;
  accessToken?: string;
  scopes: string[];
  isElevateAcademic: boolean;
}

export const initialAuthState: AuthState = {
  currentAuthStatus: AuthStatus.NOT_CHECKED,
  scopes: [],
  isElevateAcademic: false,
};
