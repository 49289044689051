import FavouriteBooks from "./FavouriteBooks";
import { connect } from "react-redux";

import { RootState } from "../redux/rootReducer";
import { Textbooks } from "../TextbookBrowser/textbooksState";

const emptyArray: Textbooks = [];

const mapStateToProps = (state: RootState) => {
  return {
    textbooks: state.textbooks.data || emptyArray,
    userGuid: state.user?.guid || "",
  };
};
export default connect(mapStateToProps)(FavouriteBooks);
