import { Ref, forwardRef } from "react";
import { Link, useParams } from "react-router-dom";

export type InstAwareLinkProps = {
  to: string;
} & React.HTMLAttributes<HTMLAnchorElement>;

const InstAwareLink = (
  props: InstAwareLinkProps,
  ref: Ref<HTMLAnchorElement>,
): JSX.Element => {
  const { instCode } = useParams() as { instCode: string };
  const { to, onClick, className, children } = props;
  const target = instCode ? `/${instCode}${to}` : to;

  return (
    <Link to={target} ref={ref} onClick={onClick} className={className}>
      {children}
    </Link>
  );
};
InstAwareLink.displayName = "InstAwareLink";

export default forwardRef(InstAwareLink);
