import { ConnectedProps, connect } from "react-redux";

import { RootState } from "../redux/rootReducer";
import { incrementNumTextbooksInGrid } from "./numTextbooksInGridReducer";
import TextbookBrowser from "./TextbookBrowser";
import { Textbooks } from "./textbooksState";

const emptyArray: Textbooks = [];

const mapStateToProps = (state: RootState) => {
  return {
    textbooks: state.textbooks.data || emptyArray,
    numTextbooksInGrid: state.numTextbooksInGrid,
    userGuid: state.user?.guid,
  };
};

const mapDispatchToProps = { incrementNumTextbooksInGrid };

const connector = connect(mapStateToProps, mapDispatchToProps);

export type TextbookBrowserProps = ConnectedProps<typeof connector>;

export default connector(TextbookBrowser);

export * from "./textbooksReducer";
export * from "./numTextbooksInGridReducer";
export * from "./textbooksState";
