import "./Categories.scss";

import { Textbooks } from "../TextbookBrowser";
import TextbookSearch from "../TextbookSearch";
import { countTextbooksByCategory, filterType } from "../lib/textbooksHelper";
import CategoryCard from "./CategoryCard";
import { useMemo } from "react";

export type CategoriesProps = {
  textbooks: Textbooks;
  userGuid: string;
};

const Categories = ({ textbooks, userGuid }: CategoriesProps) => {
  const categories = useMemo(
    () => countTextbooksByCategory(textbooks, filterType.CATEGORY),
    [textbooks],
  );

  return (
    <div className="categories-container flex-grow-1 pb-4">
      <TextbookSearch textbooks={textbooks} />
      <div className="container text-center mt-4">
        <div className="row g-4 wrapper">
          {categories.map((category) => (
            <CategoryCard
              key={category.slug}
              category={category}
              userGuid={userGuid}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Categories;
