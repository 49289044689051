import Modal from "react-bootstrap/Modal";
import "./ContactUsSuccess.scss";

import { ReactComponent as ContactSuccess } from "./contact.svg";

export interface ContactUsSuccessProps {
  isOpen: boolean;
  onDismiss: () => void;
}

const ContactUsSuccessDialog = ({
  isOpen,
  onDismiss,
}: ContactUsSuccessProps): JSX.Element => (
  <Modal
    aria-labelledby="contactUsSuccessTitle"
    aria-hidden="true"
    show={isOpen}
    onHide={() => onDismiss()}
    centered
  >
    <Modal.Header className="text-center o-modal-header--no-border u-mt1 flex-column">
      <ContactSuccess className="c-success-contact" />
      <h4 className="modal__title beta mt-2" id="contactUsSuccessTitle">
        Thanks! <br />
        We will get in touch soon
      </h4>
    </Modal.Header>
    <Modal.Body className="modal__body">
      <p className="modal__text u-pt1 u-pb7">
        In the meantime, check out how others are making an impact using Talis
        Elevate
      </p>
    </Modal.Body>
    <Modal.Footer>
      <button
        onClick={() => onDismiss()}
        className="btn btn-default"
        type="button"
      >
        Not now
      </button>
      <a
        href="https://talis.com/category/talis-elevate/"
        target="_blank"
        rel="noreferrer noopener"
        className="btn btn-primary"
      >
        View blog
      </a>
    </Modal.Footer>
  </Modal>
);

export default ContactUsSuccessDialog;
