import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { TextbookCategoryWithCount } from "../lib/textbooksHelper";

type CategoryIconStyles = {
  icon: IconProp;
  colour: string;
};

const getIconStyles = (categorySlug: string): CategoryIconStyles => {
  switch (categorySlug) {
    case "aging-and-gerontology":
      return { icon: ["fal", "scroll-old"], colour: "#DB899C" };
    case "business-and-management":
      return { icon: ["fal", "briefcase"], colour: "#BC89DB" };
    case "communication-and-media-studies":
      return { icon: ["far", "comment-alt"], colour: "#B5DD96" };
    case "counseling-and-psychotherapy":
      return { icon: ["fal", "user-friends"], colour: "#C08080" };
    case "criminology-and-criminal-justice":
      return { icon: ["fal", "badge-sheriff"], colour: "#B1AC7B" };
    case "cultural-studies":
      return { icon: ["fal", "users"], colour: "#83AD9C" };
    case "economics-and-development-studies":
      return { icon: ["fasl", "chart-column"], colour: "#AA88CC" };
    case "education":
      return { icon: ["fal", "university"], colour: "#7CA0A3" };
    case "geography-earth-and-environmental-science":
      return { icon: ["fasl", "trees"], colour: "#96B27A" };
    case "nursing":
      return { icon: ["fal", "user-nurse"], colour: "#86C1D3" };
    case "other-health-specialties":
      return { icon: ["fal", "medkit"], colour: "#AFA1A1" };
    case "political-science-and-international-relations":
      return { icon: ["fal", "handshake"], colour: "#FD78A0" };
    case "psychology":
      return { icon: ["fal", "head-side-brain"], colour: "#6DB6D6" };
    case "public-health":
      return { icon: ["fal", "heartbeat"], colour: "#4F579E" };
    case "research-methods-and-evaluation":
      return { icon: ["fal", "search"], colour: "#C59BBB" };
    case "social-work-and-social-policy":
      return { icon: ["fal", "hands-helping"], colour: "#BE888C" };
    case "sociology":
      return { icon: ["fal", "ball-pile"], colour: "#E57A7A" };
    case "study-skills":
      return { icon: ["fal", "books"], colour: "#73CC8C" };
    default:
      return { icon: ["fal", "books"], colour: "#73CC8C" };
  }
};

type CategoryInfo = {
  iconStyles: CategoryIconStyles;
  bookCount: string;
  link: string;
};

export const getCategoryInfo = (
  category: TextbookCategoryWithCount,
): CategoryInfo => {
  return {
    iconStyles: getIconStyles(category.slug),
    bookCount:
      category.bookCount === 1 ? "1 book" : `${category.bookCount} books`,
    link: `/books?category=${category.slug}`,
  };
};
