import {
  SearchableTextbook,
  Textbook,
} from "../TextbookBrowser/textbooksState";

export const makeSearchable = (textbook: Textbook): SearchableTextbook => {
  return {
    title: textbook.title,
    isbn: textbook.isbn,
    epub2: textbook.epub2,
    epub3: textbook.epub3,
    contributor1: textbook.contributor1,
    contributor2: textbook.contributor2,
    contributor3: textbook.contributor3,
    publicationDate: textbook.publicationDate,
  };
};
