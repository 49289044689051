import "./ErrorPage.scss";

export type ErrorPageProps = {
  error?: Error;
  additionalMessage?: string;
};

const ErrorPage = ({
  error,
  additionalMessage,
}: ErrorPageProps): JSX.Element => {
  return (
    <div className="wrapper d-flex align-items-center min-vh-100">
      <main className="mw-50 mt-4">
        <h1 className="c-error-page__header text-black fw-bold lh-lg mt-2 mb-1">
          Oops!
        </h1>
        <h2 className="c-error-page__subheader text-black lh-lg mt-2 mb-1 fw-light">
          Something has gone wrong
        </h2>

        <hr className="border-top border-2 border-solid w-75 my-3" />

        <section>
          {error && (
            <dl className="o-dl--inline list-unstyled d-inline-block mb-4">
              <dt>{error.name}:</dt> <dd>{error.message}</dd>
            </dl>
          )}

          {additionalMessage ? (
            <p>{additionalMessage}</p>
          ) : (
            <>
              <p>
                <strong>Need further support?</strong>
              </p>
              <p>
                You can{" "}
                <a href="mailto:support@talis.com" className="text-primary">
                  raise a support ticket
                </a>{" "}
                and we will be in touch
              </p>
            </>
          )}
        </section>
      </main>
    </div>
  );
};

export default ErrorPage;
