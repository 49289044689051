import axios from "axios";
import jsonpAdapter from "axios-jsonp";

import { PersonaUser } from "../redux/user";
import config from "../utils/config";

export const fetchAuthenticatedUser = (): Promise<PersonaUser> => {
  const url = `${config.oauthUrl}auth/login.json`;
  return axios
    .get<PersonaUser>(url, {
      withCredentials: true,
      adapter: jsonpAdapter,
      callbackParamName: "cb", // this is the name of the variable in persona, changing it will break auth
    })
    .then((response): PersonaUser => response.data);
};

export const getInstCodeFromScopes = (scopes: Array<string>): string | null => {
  let instCode = null;

  const memberScopes = scopes.filter((scope) => scope.includes("member@"));
  if (memberScopes.length) {
    //filter out devolved constraints
    const memberScope = memberScopes.filter((scope) => !scope.includes(":"))[0];
    if (memberScope) {
      instCode = memberScope.split("@")[1];
    }
  }
  return instCode;
};

export const userHasRightScopes = (
  scopes: Array<string>,
  instCode: string,
): boolean => {
  return (
    scopes.filter((scope) => {
      return scope === `member@${instCode}`;
    }).length > 0
  );
};

export const isElevateAcademic = (scopes: Array<string>): boolean => {
  return (
    scopes.filter((scope) => {
      const matchFromReadScope = scope.match(/^elevate:read@([a-zA-Z0-9-]*)$/);

      if (matchFromReadScope) {
        return scopes.includes(`elevate:write@${matchFromReadScope[1]}`);
      }
      return false;
    }).length > 0
  );
};
