import SortByDropdown from "./SortByDropdown";

export enum TextbookSortBy {
  ALPHABETICAL = "ALPHABETICAL",
  PUBLICATION_DATE = "PUBLICATION_DATE",
}

export type SortByDropdownProps = {
  selectedSortBy: TextbookSortBy;
  onSortSelect: (sortBy: TextbookSortBy) => void;
};

export default SortByDropdown;
