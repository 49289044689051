import { configureStore } from "@reduxjs/toolkit";

import { rootReducer } from "./rootReducer";
import favouriteBooksApi from "../api/favouriteBooksApiSlice";

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(favouriteBooksApi.middleware),
});

export default store;

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
