import "./fontAwesome";
import "./App.scss";

import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { addAccessToken } from "./axiosInterceptors";
import DiscoveryLayer from "./DiscoveryLayer";
import RedirectToSageOrHome from "./RedirectToSageOrHome";
import store from "./redux/store";

addAccessToken();

const App = (): JSX.Element => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path=":instCode/*" element={<DiscoveryLayer />} />
          <Route path="/" element={<RedirectToSageOrHome />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
