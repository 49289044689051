import { ContactUsData } from "../lib/elevateHelper";

export interface ContactUsFormState {
  formData: ContactUsData;
  isProcessing: boolean;
  isSuccessful: boolean;
  errorMessage?: string;
}

export enum ContactUsActionType {
  START_PROCESSING = "START_PROCESSING",
  FORM_HANDLE_CHANGE = "FORM_HANDLE_CHANGE",
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
  RESET = "RESET",
}

export type ContactUsAction =
  | { type: ContactUsActionType.START_PROCESSING }
  | {
      type: ContactUsActionType.FORM_HANDLE_CHANGE;
      payload: { key: string; value: string };
    }
  | { type: ContactUsActionType.SUCCESS }
  | { type: ContactUsActionType.FAILED; payload: { errorMessage: string } }
  | { type: ContactUsActionType.RESET; payload: { formData: ContactUsData } };

export const reducer = (
  state: ContactUsFormState,
  action: ContactUsAction,
): ContactUsFormState => {
  switch (action.type) {
    case ContactUsActionType.FORM_HANDLE_CHANGE:
      const { key, value } = action.payload;
      return {
        ...state,
        formData: {
          ...state.formData,
          [key]: value,
        },
      };

    case ContactUsActionType.START_PROCESSING:
      return { ...state, isProcessing: true };

    case ContactUsActionType.SUCCESS:
      return { ...state, isProcessing: false, isSuccessful: true };

    case ContactUsActionType.FAILED:
      return {
        ...state,
        isProcessing: false,
        isSuccessful: false,
        errorMessage: action.payload.errorMessage,
      };

    case ContactUsActionType.RESET:
      return {
        isProcessing: false,
        isSuccessful: false,
        errorMessage: undefined,
        formData: action.payload.formData,
      };

    default:
      return state;
  }
};
