import debounce from "lodash.debounce";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import isISBN from "validator/lib/isISBN";

import { SearchEventData, sendSearchEvent } from "../lib/elevateHelper";
import bookSearch from "../lib/search";
import {
  SearchableTextbook,
  Textbooks,
} from "../TextbookBrowser/textbooksState";
import { makeSearchable } from "./makeSearchable";
import peoplePng from "./people.png";
import peopleWebp from "./people.webp";
import TextbookSearchForm from "./TextbookSearchForm";

export const SEARCH_EVENT_DEBOUNCE = 2000;

export interface TextbookSearchProps {
  textbooks: Textbooks;
  onSelectTextbook: CallableFunction;
  userGuid: string;
}

const TextbookSearch = (props: TextbookSearchProps): JSX.Element => {
  const { textbooks, onSelectTextbook, userGuid } = props;
  const { instCode } = useParams() as { instCode: string };
  const [searchValue, setSearchValue] = useState("");

  const navigateToBook = (textbookAndIsbnName: string): void => {
    const isbnMatches = textbookAndIsbnName.match(/\(ISBN: (\w+)\)$/);
    const isbn = isbnMatches && isbnMatches.length > 1 ? isbnMatches[1] : null;

    const bookToOpen = textbooks.filter((book) => book.isbn === isbn);
    if (bookToOpen.length === 0) {
      return;
    }
    onSelectTextbook(bookToOpen[0]);
  };

  const searchableBooks = textbooks.map((textbook) => makeSearchable(textbook));

  const sortedSearchableBooks = searchableBooks.sort((a, b) => {
    const aDate = Number(a.publicationDate);
    const bDate = Number(b.publicationDate);
    return bDate - aDate;
  });

  const sortEditionsByPublicationDate = (
    a: SearchableTextbook,
    b: SearchableTextbook,
  ): number => {
    if (b.title === a.title && b.contributor1 === a.contributor1) {
      return Number(b.publicationDate) - Number(a.publicationDate);
    }
    return 0;
  };

  const fullBookSearch = bookSearch(sortedSearchableBooks, {
    fields: [
      "title",
      "isbn",
      "contributor1",
      "contributor2",
      "contributor3",
      "epub2",
      "epub3",
    ],
    limit: 20,
  });

  const results = fullBookSearch(searchValue).sort(
    sortEditionsByPublicationDate,
  );

  const debouncedSendAnalyticsEvent = useMemo(
    () =>
      debounce((eventData: SearchEventData, searchValue: string) => {
        if (!searchValue) {
          return;
        }
        sendSearchEvent(eventData);
      }, SEARCH_EVENT_DEBOUNCE),
    [],
  );

  const onSearchChange = (searchValue: string) => {
    setSearchValue(searchValue);

    const eventData: SearchEventData = {
      instShortCode: instCode,
      searchedByISBN: isISBN(searchValue),
      searchQuery: searchValue,
      hitCount: fullBookSearch(searchValue).length,
      userGuid: userGuid,
    };

    debouncedSendAnalyticsEvent(eventData, searchValue);
  };

  return (
    <section className="c-hero position-relative">
      <div className="position-relative z-1">
        <p className="o-strapline u-measure fw-light text-center text-black">
          The collaborative teaching and learning tool, with a fully-loaded
          collection of premium social science textbooks from Sage.
        </p>
        <TextbookSearchForm
          onChange={onSearchChange}
          onSelectBook={navigateToBook}
          results={results}
          maxResults={20}
          value={searchValue}
        />
      </div>

      <picture>
        <source type="image/webp" srcSet={peopleWebp} />
        <source type="image/png" srcSet={peoplePng} />
        <img
          className="c-hero__art position-absolute bottom-0 start-0 w-100"
          src={peoplePng}
          alt=""
        />
      </picture>
    </section>
  );
};

export default TextbookSearch;
