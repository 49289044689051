import { useParams } from "react-router-dom";

import TableOfContents, {
  EmbedChapterCallback,
  TableOfContentsWithPlayerLinks,
} from "./TableOfContents";

type ISBNRoute = {
  isbn: string;
  instCode: string;
};

interface TableOfContentsFromParamsProps {
  contents: TableOfContentsWithPlayerLinks;
  totalPages: number;
  title: string;
  onEmbedChapter: EmbedChapterCallback;
}

const TableOfContentsFromParams = (
  props: TableOfContentsFromParamsProps,
): JSX.Element => {
  const { isbn, instCode } = useParams() as ISBNRoute;
  const { contents, totalPages, title, onEmbedChapter } = props;

  return (
    <TableOfContents
      {...{ contents, totalPages, title, isbn, instCode, onEmbedChapter }}
    />
  );
};

export default TableOfContentsFromParams;
