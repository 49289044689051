import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead.bs5.css";
import "./Search.scss";

import { Typeahead } from "react-bootstrap-typeahead";
import { SearchableTextbook } from "../TextbookBrowser/textbooksState";
import { Option } from "react-bootstrap-typeahead/types/types";
import TextbookSearchMenu from "./TextBookSearchMenu";

export interface SearchProps {
  results: Array<SearchableTextbook>;
  onChange: CallableFunction;
  onSelectBook: CallableFunction;
  maxResults?: number;
  value: string;
}

const TextbookSearchForm = (props: SearchProps): JSX.Element => {
  const { results, maxResults, onSelectBook, onChange, value } = props;

  const onHandleSelect = (textbook: Option): void => {
    const book = textbook as SearchableTextbook[];
    if (book !== undefined) {
      onSelectBook(`${book[0].title} (ISBN: ${book[0].isbn})`);
    }
  };

  const labelKeyCreator = (option: Option): string => {
    const textbook = option as SearchableTextbook;
    const authors = [
      textbook.contributor1,
      textbook.contributor2,
      textbook.contributor3,
    ];
    const filteredAuthors = authors.filter((author) => author !== undefined);
    return `${textbook.title} ${filteredAuthors} ${textbook.publicationDate} ${textbook.isbn}`;
  };

  return (
    <div
      className="form-group c-search-form w-75 my-0 mx-auto"
      id="search-form"
    >
      <Typeahead
        id="textbook-search-results"
        className="c-search-results c-search-form"
        options={results}
        labelKey={labelKeyCreator}
        placeholder="Search by title, author, or ISBN"
        maxResults={maxResults}
        minLength={1}
        maxHeight="40rem"
        renderMenu={(results, menuProps) => {
          return (
            <TextbookSearchMenu
              options={results}
              menuProps={menuProps}
              value={value}
              maxResults={maxResults}
            />
          );
        }}
        onChange={(selected: Option) => onHandleSelect(selected)}
        onInputChange={(text: string) => onChange(text)}
        filterBy={() => true}
        renderInput={({ inputRef, referenceElementRef, ...inputProps }) => (
          <input
            aria-controls="search-form"
            {...inputProps}
            ref={(input) => {
              inputRef(input);
              referenceElementRef(input);
            }}
            className="form-control"
          />
        )}
      />
    </div>
  );
};

export default TextbookSearchForm;
