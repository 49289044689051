import { useEffect, useReducer, useState } from "react";

import AddToModuleSuccess from "../AddToModuleSuccess";
import FindModuleDialog, {
  ElevateModule,
  ElevateModules,
} from "../FindModuleDialog/FindModuleDialog";
import {
  convertModuleListToElevateModules,
  createChapter,
  fetchModuleList,
  filterArchivedModules,
} from "../lib/elevateHelper";
import { createChapterDescriptionFromTextbook } from "../lib/textbooksHelper";
import { Textbook } from "../TextbookBrowser/textbooksState";
import config from "../utils/config";
import { initialState, reducer } from "./embedChapter.reducer";

export type EmbedChapterProps = {
  instCode: string;
  isbn: string;
  isElevateAcademic: boolean;
  isOpen: boolean;
  chapterToEmbed?: ChapterToEmbed;
  textbook: Textbook;
  onDismiss: CallableFunction;
  guid?: string;
};

export type ChapterToEmbed = {
  title: string;
  startPageIndex: number;
  endPageIndex: number;
};

const EmbedChapter = (props: EmbedChapterProps): JSX.Element => {
  const { instCode, guid, isbn, chapterToEmbed, textbook, isElevateAcademic } =
    props;
  const [userModules, setUserModules] = useState<ElevateModules>([]);

  const [state, dispatcher] = useReducer(reducer, initialState);

  useEffect(() => {
    if (!guid || !isElevateAcademic) {
      return;
    }

    fetchModuleList(instCode, guid)
      .then((response) => filterArchivedModules(response.data))
      .then((modules) => convertModuleListToElevateModules(modules))
      .then(setUserModules)
      .catch((err) => console.error(err));
  }, [instCode, guid, isElevateAcademic]);

  useEffect(() => {
    dispatcher({ type: "RESET" });
  }, [props.isOpen]);

  const onSelectModule = (selectedModule: ElevateModule) => {
    if (!chapterToEmbed || !guid) {
      return;
    }

    dispatcher({ type: "START_PROCESSING" });
    createChapter(instCode, {
      isbn,
      title: chapterToEmbed.title,
      description: createChapterDescriptionFromTextbook(textbook),
      pageStartIndex: chapterToEmbed.startPageIndex,
      pageEndIndex: chapterToEmbed.endPageIndex,
      userGuid: guid,
      moduleId: selectedModule.id,
    })
      .then((resourceId) => {
        dispatcher({
          type: "SUCCESS",
          payload: {
            moduleTitle: selectedModule.title,
            resourceUrl: `${config.elevateUrl}${instCode}/player#/modules/${selectedModule.id}/resources/${resourceId}`,
          },
        });
      })
      .catch((error) => {
        dispatcher({
          type: "FAILED",
          payload: { errorMessage: error.message },
        });
      });
  };

  if (state.resourceUrl && chapterToEmbed && state.moduleTitle) {
    return (
      <AddToModuleSuccess
        isOpen={props.isOpen}
        chapterTitle={chapterToEmbed.title}
        textbookTitle={textbook.title}
        moduleTitle={state.moduleTitle}
        onDismiss={props.onDismiss}
        viewResourceUrl={state.resourceUrl}
      />
    );
  }

  return (
    <FindModuleDialog
      {...props}
      errorMessage={state.errorMessage}
      isProcessing={state.isProcessing}
      onSelectModule={onSelectModule}
      modules={userModules || []}
      textbookTitle={textbook.title}
      chapterTitle={chapterToEmbed !== undefined ? chapterToEmbed.title : ""}
    />
  );
};

export default EmbedChapter;
