import axios, { AxiosRequestHeaders, InternalAxiosRequestConfig } from "axios";

import store from "../redux/store";
import { AuthStatus } from "../withAuth/authState";

export const addAccessToken = (): void => {
  axios.interceptors.request.use(
    (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
      const authToken = getAuthToken();
      return {
        ...config,
        headers: {
          ...config.headers,
          ...(authToken && { Authorization: `Bearer ${authToken}` }),
        } as AxiosRequestHeaders,
      };
    },
    (error) => Promise.reject(error),
  );
};

const getAuthToken = (): string | null => {
  const { currentAuthStatus, accessToken } = store.getState().auth;

  if (currentAuthStatus === AuthStatus.AUTHORISED && accessToken) {
    return accessToken;
  }

  return null;
};
