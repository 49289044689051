import { connect } from "react-redux";
import { RootState } from "../redux/rootReducer";
import Categories from "./Categories";
import { Textbooks } from "../TextbookBrowser";

const emptyArray: Textbooks = [];

const mapStateToProps = (state: RootState) => {
  return {
    textbooks: state.textbooks.data || emptyArray,
    userGuid: state.user?.guid || "",
  };
};

export default connect(mapStateToProps)(Categories);
